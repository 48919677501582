import React, {useState} from "react"
import MyPagination from "../../MyPagination"
import MySortedTh, {SortType, onThClickHelper} from "../../MySortTh"
import { RefProperty } from "../../../Model/Model"

function ServiceTable(props: any) {
  const [theUsers, setTheUsers] = useState<RefProperty[]>()
  const [sortColumnName, setSortColumnName] = useState<string>("")
  const [sortType, setSortType] = useState<string>("")

  if(theUsers !== props.data)
  {
    setTheUsers(props.data as RefProperty[])
  }
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const changeCurrentPage = (p: number) =>{
    setCurrentPage(p)
  }

  const onPageSizeChange = (p: number) => {
    setPageSize(p)
    setCurrentPage(1)
  }

  const getDisplayItems = () => {
    let theResult = Object.values(Object.assign({}, theUsers || []))
    if(sortColumnName)
    {
      if(sortType === SortType.DESC)
      {
        theResult = (theResult as any[]).sort((a, b) => (b[sortColumnName] || "").toString().localeCompare(a[sortColumnName] || ""))
      }
      else{
        theResult = (theResult as any[]).sort((a, b) => (a[sortColumnName] || "").toString().localeCompare(b[sortColumnName] || ""))
      }
    }

    if(totalCount !== theResult.length)
    {
      setTotalCount(theResult.length)
    }
    
    return theResult
  }

  const [totalCount, setTotalCount] = useState<number>()

  return (
    <div>
    <table className="dataListTbMainList">
      <thead>
        <tr>
          <MySortedTh
            CurrentSortName={sortColumnName}
            SortAble={true}
            SortColumnName={'name'}
            SortType={sortType}
            //Style={{ width: '5%', cursor: 'pointer' }}
            Title="Name"
            onThClick={() => onThClickHelper(sortColumnName, sortType, 'name', setSortColumnName, setSortType)}
                      />
          <th style={{width: "100px"}}>Edit</th>
          <th style={{width: "100px"}}>Delete</th>
        </tr>
      </thead>
      <tbody>
        {getDisplayItems() && (getDisplayItems() as RefProperty[]).slice((currentPage - 1) * pageSize, currentPage * pageSize) 
        && (getDisplayItems() as RefProperty[]).slice((currentPage - 1) * pageSize, currentPage * pageSize).map((item: RefProperty) =>{
          return <tr key={item.id}>
          <td>{item.name}</td>
          <td>
            <div>
              <button
                className="btn btn-primary"
                onClick={() => {
                  props.handleShow(item);
                }}
              >
                <i className="fas fa-edit"></i>
              </button>
            </div>
          </td>
          <td>
          <div>
            <button
                className="btn btn-primary"
                onClick={() => {
                  props.handleDeleteShow(item);
                }}
              >
                <i className="fas fa-trash-alt"></i>
              </button>
          </div>
          </td>
        </tr>
        }) }
      </tbody>
    </table>
      {totalCount && <MyPagination totalCount={totalCount} onPageSizeChange={(p) => onPageSizeChange(p)} currentPage={currentPage}
      onClick={(p) => changeCurrentPage(p)}/>}
  </div>
  );
}

export default ServiceTable;
