import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import './DraggableService.css'

function DraggableService(props) {
  return (
    <Draggable draggableId={props.service.id} index={props.index}>
      {(provided) => (
        <div
          key={props.service.id}
          className="draggable-service"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {props.service.name}
        </div>
      )}
    </Draggable>
  );
}

export default DraggableService;
