import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import BusinessIcon from "@material-ui/icons/Business";
import SettingsIcon from "@material-ui/icons/Settings";
import PostAddIcon from '@material-ui/icons/PostAdd';
import EditIcon from '@material-ui/icons/Edit';
import { Container, Row, Col, Card } from "react-bootstrap";
import "./Admin.css";

//
function Admin() {
  return (
    <>
      <div className="container pb-5">
        <h2 className="my-5 text-center">Admin Menu</h2>
        <Row>
            <div className="col-md-6 col-lg-4">
            <Card className="pt-4 mb-4 admin">
                <AccountCircleIcon className="admin-card-icon" />
                <Card.Body className="card-body pt-0">
                <Card.Title>Manage Users</Card.Title>
                <Card.Text>
                    Click here to manage users
                </Card.Text>
                <Link className="btn btn-primary" to="admin/users">
                    Manage Users
                </Link>
                </Card.Body>
            </Card>
            </div>

            <div className="col-md-6 col-lg-4">
            <Card className="pt-4 mb-4 admin">
                <BusinessIcon className="admin-card-icon" />
                <Card.Body className="card-body pt-0">
                <Card.Title>Manage Clients</Card.Title>
                <Card.Text>
                    Click here to manage clients
                </Card.Text>
                <Link className="btn btn-primary" to="admin/clients">
                    Manage Clients
                </Link>
                </Card.Body>
            </Card>
            </div>

            <div className="col-md-6 col-lg-4">
            <Card className="pt-4 mb-4 admin">
                <SettingsIcon className="admin-card-icon" />
                <Card.Body className="card-body pt-0">
                <Card.Title>Manage Services</Card.Title>
                <Card.Text>
                    Click here to manage services
                </Card.Text>
                <Link className="btn btn-primary" to="admin/services">
                    Manage Services
                </Link>
                </Card.Body>
            </Card>
            </div>

            <div className="col-md-6 col-lg-4">
            <Card className="pt-4 mb-4 admin">
                <PostAddIcon className="admin-card-icon" />
                <Card.Body className="card-body pt-0">
                <Card.Title>Create "Discover What's New" Post</Card.Title>
                <Card.Text>
                    Click here to create new post
                </Card.Text>
                <Link className="btn btn-primary" to="admin/newpost">
                    Create Post
                </Link>
                </Card.Body>
            </Card>
            </div>

            <div className="col-md-6 col-lg-4">
            <Card className="pt-4 mb-4 admin">
                <EditIcon className="admin-card-icon" />
                <Card.Body className="pt-0">
                <Card.Title>Manage "Discover What's New" Posts</Card.Title>
                <Card.Text>
                    Click here to manage existing posts
                </Card.Text>
                <Link className="btn btn-primary" to="admin/manageposts">
                    Manage Posts
                </Link>
                </Card.Body>
            </Card>
            </div>
        </Row>
    </div>
    </>
  );
}

export default Admin;
