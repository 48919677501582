import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { Container, Row, Col, Modal, Form, Button } from "react-bootstrap";
import axios from "axios";

const UserGroupModal = (props) => {
    const [formData, setFormData] = useState(props.usergroup);
    const [availableOptions, setAvailableOptions] = useState([]);
    const isEditable = props.usergroup.name ? true : false;

    useEffect(() => {
        setFormData(props.usergroup);
    }, [props.usergroup])

    useEffect(() => {
        getAvailableOptions(props.services);
    }, [])

    const getAvailableOptions = (services) => {
        let output = {};
        if (services && services.length > 0) {
            output = props.services.map(service => ({ "value": service.id, "label": service.name }));
        }
        setAvailableOptions(output);
    }

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    const handleSelectChange = (e) => {
        let options = Array.isArray(e) ? e.map(x => x.value) : [];
        setFormData({
            ...formData,
            "clientProductIds": options
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("submit", formData);

        var data = new FormData();
        if (formData.id) {
            data.append("id", formData.id);
        }
        data.append("name", formData.name);
        data.append("clientName", props.client.name);
        data.append("clientId", formData.clientId);
        data.append('clientProductIds', JSON.stringify(formData.clientProductIds));

        let method = props.submitButtonText == 'Add' ? 'New' : 'Update';

        props.handleSubmit(`/api/v1/UserGroup/${method}`, data);
    }

    return (
        <>
            {props.usergroup && (
                <Modal size="lg" show={props.show} onHide={props.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form id="add-usergroup-form" onSubmit={handleSubmit}>
                            <Row>
                                <Form.Group as={Col} controlId="Name">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" name="name" defaultValue={formData.name} onChange={handleChange} disabled={isEditable}></Form.Control>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Col>
                                    <h5>User Access</h5>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="client">
                                    <Form.Label>Client</Form.Label>
                                    <Form.Control type="text" defaultValue={props.client.name} disabled></Form.Control>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Col className="col-12">
                                    {props.services && props.services.length > 0 && (
                                        <Select className="dropdown" placeholder="Select Option" options={availableOptions}
                                            value={formData.clientProductIds.length > 0 && availableOptions.filter(obj => formData.clientProductIds.includes(obj.value))}
                                            onChange={handleSelectChange}
                                            isMulti
                                            isClearable />
                                    )}
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={props.handleClose}>Cancel</Button>
                        <Button variant="primary" type="submit" form="add-usergroup-form">{props.submitButtonText}</Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    )
}

export default UserGroupModal;