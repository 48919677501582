import React, { useState } from "react"
import MyPagination from "../../MyPagination"
import MySortedTh, {SortType} from "../../MySortTh"
import { UserGroup } from "../../../Model/Model"

const UserGroupsTable = (props: any) => {

  const [theUsers, setTheUsers] = useState<UserGroup[]>()
  const [sortColumnName, setSortColumnName] = useState<string>("")
  const [sortType, setSortType] = useState<string>("")

  if(theUsers !== props.data)
  {
    setTheUsers(props.data as UserGroup[])
  }
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const changeCurrentPage = (p: number) =>{
    setCurrentPage(p)
  }

  const onPageSizeChange = (p: number) => {
    setPageSize(p)
    setCurrentPage(1)
  }

  const getDisplayItems = () => {
    let theResult = Object.values(Object.assign({}, theUsers || []))
    if(sortColumnName)
    {
      if(sortType === SortType.DESC)
      {
        theResult = (theResult as any[]).sort((a, b) => (b[sortColumnName] || "").toString().localeCompare(a[sortColumnName] || ""))
      }
      else{
        theResult = (theResult as any[]).sort((a, b) => (a[sortColumnName] || "").toString().localeCompare(b[sortColumnName] || ""))
      }
    }

    if(totalCount !== theResult.length)
    {
      setTotalCount(theResult.length)
    }
    
    return theResult
  }

  const [totalCount, setTotalCount] = useState<number>()
  // useEffect(()=>{
  //   if(getDisplayUsers())
  //   {
  //     setTotalCount(getDisplayUsers().length)
  //   }
  // }, [totalCount])

  const onThClick = (theSortColumnName: string) => {
    if(!sortColumnName)
    {
      setSortColumnName(theSortColumnName)
      setSortType(SortType.ASC)
    }

    if(theSortColumnName === sortColumnName)
    {
      if(sortType === SortType.DESC)
      {
        setSortType(SortType.ASC)
      }
      else
      {
        setSortType(SortType.DESC)
      }
    }
    else{
      setSortColumnName(theSortColumnName)
      setSortType(SortType.ASC)
    }
  }

  return (
    <>
      <div className="button-container float-right">
        <button className="btn btn-secondary" onClick={props.handleAddUserGroupsShow.bind(this)}>
          <i className="fas fa-plus"></i>&nbsp; Add New User Group
        </button>
      </div>

      <div>
    <table className="dataListTbMainList">
      <thead>
        <tr>
          <MySortedTh
            CurrentSortName={sortColumnName}
            SortAble={true}
            SortColumnName={'name'}
            SortType={sortType}
            //Style={{ width: '5%', cursor: 'pointer' }}
            Title="Name"
            onThClick={() => onThClick('name')}
                      />
          <MySortedTh
            CurrentSortName={sortColumnName}
            SortAble={true}
            SortColumnName={'client'}
            SortType={sortType}
            //Style={{ width: '5%', cursor: 'pointer' }}
            Title="Client"
            onThClick={() => onThClick('client')}
          />
          <MySortedTh
            CurrentSortName={sortColumnName}
            SortAble={true}
            SortColumnName={'services'}
            SortType={sortType}
            //Style={{ width: '5%', cursor: 'pointer' }}
            Title="Services"
            onThClick={() => onThClick('services')}
          />
          <th style={{width: "100px"}}>Edit</th>
          <th style={{width: "100px"}}>Delete</th>
        </tr>
      </thead>
      <tbody>
        {getDisplayItems() && (getDisplayItems() as UserGroup[]).slice((currentPage - 1) * pageSize, currentPage * pageSize) 
        && (getDisplayItems() as UserGroup[]).slice((currentPage - 1) * pageSize, currentPage * pageSize).map((item: UserGroup) =>{
          return <tr key={item.id}>
          <td>{item.name}</td>
          <td>{item.client}</td>
          <td>{item.services}</td>
          <td>
            <div>
              <button className="btn btn-primary" onClick={props.handleUpdateUserGroupsShow.bind(this, item)}
              >
                <i className="fas fa-edit"></i>
              </button>
            </div>
          </td>
          <td>
          <div>
            <button className="btn btn-primary" onClick={props.handleDeleteUserGroupsShow.bind(this, item)}>
              <i className="fas fa-trash-alt"></i>
            </button>
          </div>
          </td>
        </tr>
        }) }
      </tbody>
    </table>
      {totalCount && <MyPagination totalCount={totalCount} onPageSizeChange={(p) => onPageSizeChange(p)} currentPage={currentPage}
      onClick={(p) => changeCurrentPage(p)}/>}
  </div>
    </>
  );
}

export default UserGroupsTable;
