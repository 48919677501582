import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = React.createContext({
  isSignedIn: false,
  isLoading: true
});

function AuthProvider(props) {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios.get('/account/isAuthenticated', { withCredentials: true })
      .then(function (response) {
        setIsSignedIn(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error.response);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <AuthContext.Provider value={{ isSignedIn, isLoading }}>
      {props.children}
    </AuthContext.Provider>
  )
}

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer };