import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Tab, Nav } from "react-bootstrap";
import TabContent from "../../Components/TabContent";
import UserGroupsTable from "../Tables/UserGroupsTable";
import UserGroupModal from "../Modals/UserGroupModal";
import DeleteModal from "../Modals/DeleteModal";
import ServiceUpdateForm from "../Forms/ServiceUpdateForm";
import axios from "axios";
import "./AdminClientPane.css";
import DroppableColumn from "../DroppableColumn";
import { EvStationTwoTone, SelectAll } from "@material-ui/icons";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";

function AdminClientPane(props) {
  const [allServices, setAllServices] = useState();
  const [update, setUpdate] = useState(false);
  const [updateClient, setUpdateClient] = useState(false);
  const [image, setImage] = useState(null);
  const [clientName, setClientName] = useState(props.client.name);
  const [selectedImage, setSelectedImage] = useState(props.client.logoUrl);
  const [isLoading, setIsLoading] = useState(false);
  const [isUserGroupLoading, setIsUserGroupLoading] = useState(false);

  const [userGroupServices, setUserGroupServices] = useState([]);
  const [UserGroups, setUserGroups] = useState([]);
  const [userGroupsUpdate, setUserGroupsUpdate] = useState(false);
  const [addUserGroupShow, setAddUserGroupShow] = useState(false);
  const [userGroupNameInputKey, setUserGroupNameInputKey] = useState(Math.random());
  const [title, setTitle] = useState("Add new UserGroup");
  const [selectedUserGroup, setSelectedUserGroup] = useState({
    id: null,
    name: "",
    clientProductIds: [],
    clientId: props.client.id
  });

  const [submitButtonText, setSubmitButtonText] = useState("Add");

  const [deleteUserGroupShow, setDeleteUserGroupShow] = useState(false);
  const [deleteUserGroupTitle, setDeleteUserGroupTitle] = useState("user group");

  const handleAddUserGroupClose = () => setAddUserGroupShow(false);
  const handleUpdateUserGroupsShow = (usergroup) => {
    setUserGroupNameInputKey(Math.random());
    setTitle("Update User Group");
    setSubmitButtonText("Update");
    setSelectedUserGroup(usergroup);
    setAddUserGroupShow(true);
  };
  const handleDeleteUserGroupsShow = (usergroup) => {
    setDeleteUserGroupShow(true);
    setDeleteUserGroupTitle(`user group ${usergroup.name}`);
    setSelectedUserGroup(usergroup);
  }
  const handleAddUserGroupsShow = () => {
    setUserGroupNameInputKey(Math.random());
    setTitle("Add New User Group");
    setSubmitButtonText("Add");
    setSelectedUserGroup({
      id: null,
      name: "",
      clientProductIds: [],
      clientId: props.client.id
    });
    setAddUserGroupShow(true);
  }

  const handleAddUserGroupSubmit = (url,formData) => {
    setIsUserGroupLoading(true);
    setAddUserGroupShow(false);
    axios.post(url, formData).then(data => {
      setUserGroupsUpdate(!userGroupsUpdate);
      setIsUserGroupLoading(false);
    }).catch(function (error) {
          // handle error
        console.log(error);
        toast.error("Something went wrong. " + error);
        setIsUserGroupLoading(false);
    })
    
  }

  const handleDeleteUserGroupClose = () => {
    setDeleteUserGroupShow(false);
  }

  const handleDeleteUserGroup = () => {
    setDeleteUserGroupShow(false);
    setIsUserGroupLoading(true);
    var data = new FormData();
    data.append("id", selectedUserGroup.id);
    data.append("name", selectedUserGroup.name);
    data.append("clientId", selectedUserGroup.clientId);
    data.append('clientProductIds', JSON.stringify(selectedUserGroup.clientProductIds));

    axios.post(`/api/v1/UserGroup/Delete`, data
    ).catch(function (error) {
      // handle error
      console.log(error);
      setIsUserGroupLoading(false);
    }).then(data => {
      setUserGroupsUpdate(!userGroupsUpdate);
      setIsUserGroupLoading(false);
    })
  }

  useEffect(() => {
    axios.get(`/api/v1/UserGroup/${props.client.id}`, { withCredentials: true }
    ).then(response => {
      console.log(response);
      var usergroups = [];
      response.data.userGroups.forEach(item => {
        usergroups.push({
          id: item.id,
          name: item.name,
          client: item.clientName,
          clientId: item.clientId,
          clientProductIds: item.clientProductIds,
          services: item.clientProductNames
        })
      });
      setUserGroups(usergroups);
    })
  }, [userGroupsUpdate])

  useEffect(() => { }, [update]);
  useEffect(() => {
    let url = "/api/v1/Products/All/" + props.client.id;
    console.log("client", props.client);
    axios
      .get(url, { withCredentials: true })
      .then(function (response) {
        var available = [];
        var my = [];
        var hidden = [];
        for (let category of Object.keys(response.data.availableProducts)) {
          for (let service of response.data.availableProducts[category]) {
            available.push(service);
          }
        }
        for (let category of Object.keys(response.data.subscribedProducts)) {
          for (let service of response.data.subscribedProducts[category]) {
            my.push(service);
          }
        }
        for (let category of Object.keys(response.data.hiddenProducts)) {
          for (let service of response.data.hiddenProducts[category]) {
            hidden.push(service);
          }
        }
        var services = {
          "My Services": my,
          "Hidden Services": hidden,
          "Available Services": available,
          };
        
        setAllServices(services);
        setUserGroupServices(my);
      })
      .catch(function (error) {
        // handle error
        console.log(error.response);
      });
  }, [updateClient]);

  //============================================================================================
  //   Functions
  //============================================================================================
  /////////////////////// Handle Form
  const handleLogoChange = (event) => {
    setSelectedImage(URL.createObjectURL(event.target.files[0]));
    setImage(event.target.files[0]);
    console.log("changed image:",event.target.files[0])
  };

  /////////////////////// Handle Drag and Drop
  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    console.log("destination:", destination);
    console.log("source:", source);
    console.log("draggableId:", draggableId);
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    if (destination.droppableId === source.droppableId) {
      let newServicesIds = getIdsFromServices(
        allServices[destination.droppableId]
      );
      console.log("same column before:", newServicesIds);
      newServicesIds.splice(source.index, 1);
      newServicesIds.splice(destination.index, 0, draggableId);
      console.log("same column after:", newServicesIds);

      var newServices = allServices;
      console.log("new Services before:", newServices);
      newServices[destination.droppableId] = getServicesFromIds(
        newServicesIds,
        newServices
      );
      setAllServices(newServices);
      setUpdate(!update);
      console.log("new Services after:", newServices);
      return;
    }

    let startServicesIds = getIdsFromServices(allServices[source.droppableId]);
    startServicesIds.splice(source.index, 1);
    console.log("start services", startServicesIds);

    let finishServicesIds = getIdsFromServices(
      allServices[destination.droppableId]
    );
    finishServicesIds.splice(destination.index, 0, draggableId);
    console.log("finish services", finishServicesIds);
    var newServices = allServices;
    var startServices = getServicesFromIds(startServicesIds, allServices);
    console.log("starServices after", startServices);
    var finishServices = getServicesFromIds(finishServicesIds, allServices);
    console.log("finishServices after", finishServices);
    newServices[source.droppableId] = startServices;
    newServices[destination.droppableId] = finishServices;
    setAllServices(newServices);
    console.log("finally", newServices);
    setUpdate(!update);
  };

  function getIdsfromAllServicesbyType(typeName) {
    var services = allServices[typeName];
    var ids = [];
    for (let s of services) {
      ids.push(s.id);
    }
    return ids;
  }

  function getIdsFromServices(services) {
    let ids = [];
    for (let s of services) {
      ids.push(s.id);
    }
    return ids;
  }

  function getServicesFromIds(IdList, serviceArray) {
    let services = [];
    for (let id of IdList) {
      for (let k of Object.keys(serviceArray)) {
        var index = serviceArray[k].findIndex((x) => x.id == id);
        if (index > -1) {
          services.push(serviceArray[k][index]);
        }
      }
    }
    console.log("services from ids:", services);
    return services;
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////  UPDATE CLIENT /////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////
  function uploadImage() {
    let formData = new FormData();
    formData.append("file", image);
    console.log("image from uploadImage:",image)
    let request = axios({
      method: "post",
      url: "/api/v1/Files/UploadImage",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
    return request;
  }
  const handleUpdateClientSubmit = (event) => {
    var name = event.target.name.value;
    console.log("name from update submit",name)
    event.preventDefault();
    console.log(name);
    console.log()

    setIsLoading(true);
    console.log("image:", image);
    if (image !== null) {
      uploadImage().then((response) => {
        var formData = new FormData();
        var logoUrl = response.data.url;
        formData.append("id", props.client.id);
        formData.append("name", name);
        formData.append("logoUrl", response.data.url);
        axios({
          method: "post",
          url: "/api/v1/Client/Update",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            accept: "text/plain",
          },
          withCredentials: true,
        }).then((response) => {
          var serviceFormData = new FormData();
          serviceFormData.append("clientId", props.client.id);
          console.log("clientId", props.client.id);
          serviceFormData.append(
            "availableProductIds",
            JSON.stringify(getIdsfromAllServicesbyType("Available Services"))
          );
          console.log(
            "availableProductIds:",
            JSON.stringify(getIdsfromAllServicesbyType("Available Services"))
          );
          serviceFormData.append(
            "hiddenProductIds",
            JSON.stringify(getIdsfromAllServicesbyType("Hidden Services"))
          );
          console.log(
            "hiddenProductIds:",
            JSON.stringify(getIdsfromAllServicesbyType("Hidden Services"))
          );
          serviceFormData.append(
            "subscribedProductIds",
            JSON.stringify(getIdsfromAllServicesbyType("My Services"))
          );
          console.log(
            "subscribedProductIds:",
            JSON.stringify(getIdsfromAllServicesbyType("My Services"))
          );
          axios({
            method: "post",
            url: "/api/v1/Products/Update/State",
            data: serviceFormData,
            headers: {
              "Content-Type": "multipart/form-data",
              accept: "text/plain",
            },
            withCredentials: true,
          })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then((response) => {
              setClientName(name);
              props.onSubmitClientNameChange(name, logoUrl, props.client);
              setUpdate(!update);
              setUpdateClient(!updateClient);
              setUserGroupsUpdate(!userGroupsUpdate);
            })
            .then((response) => {
              setIsLoading(false);
            });
        });
      });
    } else {
      console.log("id:", props.client.id);
      console.log("name:", name);
      console.log("logoUrl:", props.client.logoUrl);
      var formData = new FormData();
      formData.append("id", props.client.id);
      formData.append("name", name);
      var logoUrl = props.client.logoUrl;
      formData.append("logoUrl", props.client.logoUrl);
      axios({
        method: "post",
        url: "/api/v1/Client/Update",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          accept: "text/plain",
        },
        withCredentials: true,
      }).then((response) => {
        var serviceFormData = new FormData();
        serviceFormData.append("clientId", props.client.id);
        console.log("clientId", props.client.id);
        serviceFormData.append(
          "availableProductIds",
          JSON.stringify(getIdsfromAllServicesbyType("Available Services"))
        );
        console.log(
          "availableProductIds:",
          JSON.stringify(getIdsfromAllServicesbyType("Available Services"))
        );
        serviceFormData.append(
          "hiddenProductIds",
          JSON.stringify(getIdsfromAllServicesbyType("Hidden Services"))
        );
        console.log(
          "hiddenProductIds:",
          JSON.stringify(getIdsfromAllServicesbyType("Hidden Services"))
        );
        serviceFormData.append(
          "subscribedProductIds",
          JSON.stringify(getIdsfromAllServicesbyType("My Services"))
        );
        console.log(
          "subscribedProductIds:",
          JSON.stringify(getIdsfromAllServicesbyType("My Services"))
        );
        axios({
          method: "post",
          url: "/api/v1/Products/Update/State",
          data: serviceFormData,
          headers: {
            "Content-Type": "multipart/form-data",
            accept: "text/plain",
          },
          withCredentials: true,
        })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then((response) => {
            setClientName(name);
            props.onSubmitClientNameChange(name, logoUrl, props.client);
            setUpdate(!update);
            setUpdateClient(!updateClient);
            setUserGroupsUpdate(!userGroupsUpdate);
          })
          .then((response) => {
            setIsLoading(false);
          });
      });
    }
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  const handleUpdateServiceSubmit = (event) => {
    event.preventDefault();
    var name = event.target.name.value;
    var url = event.target.url.value;
  };

  //============================================================================================
  // Render
  //=============================================================================================

  return (
    <div className="tabs">
      <Tab.Container defaultActiveKey="clientSettings">
        <Nav variant="tabs" as="ul">
          <Nav.Item as="li">
            <Nav.Link eventKey="clientSettings">Client Settings</Nav.Link>
          </Nav.Item>

          <Nav.Item as="li">
            <Nav.Link eventKey="serviceSettings">Configure Services</Nav.Link>
          </Nav.Item>

          <Nav.Item as="li">
            <Nav.Link eventKey="usergroupsSettings">Configure User Groups</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="clientSettings">
            <>
              {isLoading ? (
                <Loader
                  className="nothing-display"
                  type="Oval"
                  color="#0F204B"
                  height={100}
                  width={100}
                />
              ) : (
                <Container>
                  <Row>
                    <Col>
                      <h4>{clientName}</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form
                        onSubmit={handleUpdateClientSubmit}
                      >
                        <Row>
                          <Form.Group as={Col} controlId="name">
                            <Form.Label>Client Name</Form.Label>
                            <Form.Control
                              as="input"
                              defaultValue={clientName}
                            />
                          </Form.Group>
                          {console.log("selectedImage from form:", selectedImage)}
                          {selectedImage === "" ? null : (
                            <img
                              src={selectedImage}
                              alt="image selected"
                              className="img-thumbnail"
                            />
                          )}
                          <Form.Group as={Col} controlId="image">
                            <Form.File
                              label="Upload Image"
                              onChange={(event) => handleLogoChange(event)}
                            />
                          </Form.Group>
                          <Col className="float-right">
                            <Button
                              variant="primary"
                              type="submit"
                            >
                              Update Client
                            </Button>
                          </Col>
                        </Row>

                      </Form>
                    </Col>

                  </Row>
                  <Row>
                    {allServices !== undefined ? (
                      <DragDropContext onDragEnd={onDragEnd}>
                        {console.log("inside dnd:", allServices)}
                        <Container className="dnd-container">
                          <DroppableColumn
                            title="My Services"
                            services={allServices["My Services"]}
                          />
                          <DroppableColumn
                            title="Available Services"
                            services={allServices["Available Services"]}
                          />
                          <DroppableColumn
                            title="Hidden Services"
                            services={allServices["Hidden Services"]}
                          />
                        </Container>
                      </DragDropContext>
                    ) : null}
                  </Row>
                </Container>
              )}
            </>
          </Tab.Pane>

          <Tab.Pane eventKey="serviceSettings">
            {allServices !== undefined ? (
              allServices["My Services"].length > 0 ? (
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Row>
                    <Col className="col-3">
                      <Nav variant="pills" className="flex-column">
                        {allServices["My Services"].map((service) => (
                          <Nav.Item key={service.id}>
                            <Nav.Link eventKey={"pill-" + service.id}>
                              {service.name}
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                    </Col>
                    <Col className="col-9">
                      <Tab.Content>
                        {allServices["My Services"].map((service) => (
                          <Tab.Pane
                            eventKey={"pill-" + service.id}
                            key={service.id}
                          >
                            <ServiceUpdateForm service={service} clientId={props.client.id} availableOptions={props.vapOptions} />
                          </Tab.Pane>
                        ))}
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              ) : null
            ) : null}
          </Tab.Pane>

          <Tab.Pane eventKey="usergroupsSettings" className="usergroup">
            {isUserGroupLoading && <div className="loadingContainer"><Loader
              className="nothing-display"
              type="Oval"
              color="#0F204B"
              height={100}
              width={100}
            /></div>}
            <UserGroupsTable
              data={UserGroups}
              handleAddUserGroupsShow={handleAddUserGroupsShow}
              handleUpdateUserGroupsShow={handleUpdateUserGroupsShow}
              handleDeleteUserGroupsShow={handleDeleteUserGroupsShow}
            ></UserGroupsTable>
            {
              userGroupServices && userGroupServices.length > 0 && (
                <UserGroupModal
                  key={selectedUserGroup.name + userGroupServices.length.toString() + userGroupNameInputKey.toString()}
                  show={addUserGroupShow}
                  title={title}
                  client={props.client}
                  usergroup={selectedUserGroup}
                  services={userGroupServices}
                  handleSubmit={handleAddUserGroupSubmit}
                  handleClose={handleAddUserGroupClose}
                  submitButtonText={submitButtonText}
                />
              )
            }
            <DeleteModal
              show={deleteUserGroupShow}
              handleClose={handleDeleteUserGroupClose}
              handleDelete={handleDeleteUserGroup}
              type={deleteUserGroupTitle}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}

export default AdminClientPane;
