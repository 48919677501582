import React from "react";
import { Card } from "react-bootstrap";
import "./DroppableColumn.css";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DraggableService from "../DraggableService";

function DroppableColumn(props) {
  return (
    <div className="block block-transparent column-container">
      <h5>{props.title}</h5>
      
        <Droppable droppableId={props.title} className="droppable-element">
          {(provided) => (
            <div className="service-list" ref={provided.innerRef} {...provided.droppableProps}>
              {props.services.length > 0 ? 
              <>
               {props.services.map((service, index) => (
                <DraggableService
                  key={service.id}
                  service={service}
                  index={index}
                />
              ))}
              </>:null}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      
    </div>
  );
}

export default DroppableColumn;
