import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Tab, Nav, Button } from "react-bootstrap";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Link } from "react-router-dom";
import './NewPost.css';
import PostForm from "../../../Components/Forms/PostForm";

function NewPost() {

  const [formType, setFormType] = useState("new")
  
    return (
      <div className="container pb-5">
        <Row>
          <Col className="my-4">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="large" />}
              aria-label="breadcrumb"
            >
              <Link to="/admin">Admin</Link>
              <Typography className="MuiBreadcrumbs-li-last">
                New Post
              </Typography>
            </Breadcrumbs>
          </Col>
        </Row>
        <Row>
          <Col className="col-lg-8">
            <h5 className="text-left">New Post</h5>
              <Row>
                <PostForm post={null} formType={formType}></PostForm>
              </Row>
          </Col>
        </Row>
      </div>

        );
};

export default NewPost;