import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Header.css";
import Dropdown from "react-bootstrap/Dropdown";
import { NavLink } from "react-router-dom";
import { AuthConsumer } from "../../Context/AuthContext";
import { AdminConsumer } from "../../Context/AdminContext";

function Header() {
  const [initials, setInitials] = useState("");
  const appName = "Evolve";
  const searchUri = `${window.location.pathname}${window.location.search}`;

  useEffect(() => {
    axios
      .get("/account/UserName", { withCredentials: true })
      .then(function (response) {
        var res = response.data.split(",");
        if (res.length === 2) {
          setInitials(
            res[1].trim().charAt(0).toUpperCase() +
              res[0].trim().charAt(0).toUpperCase()
          );
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error.response);
      });
  }, []);

  return (
    <header className="o-header sticky-top">
      <AuthConsumer>
        {({ isSignedIn }) => (
          <nav className="o-header-nav">
            <a href="#" className="logo logo-sm" target="_self">
              <img
                className="logo-image"
                src="/assets/images/logo/DNV_logo_RGB.svg"
                alt="DNV Logo"
              />
            </a>

            {isSignedIn ? (
              <div>
                <div className="o-header-right">
                  <NavLink to="/" className="o-header-product">
                    <img
                      className="logo-image"
                      src="/assets/images/EVOLVE-logo.png"
                      alt="App Logo"
                    />
                  </NavLink>
                  <ul className="o-header-links">
                    <li id="Home" className="o-header-item">
                      <NavLink className="o-header-link" to="/" exact>
                        Home
                      </NavLink>
                                      </li>
                                      {/*<li id="InternalDocumentStorage" className="o-header-item">*/}
                                      {/*    <NavLink className="o-header-link" to="/InternalDocumentStorage" exact>*/}
                                      {/*        Internal Document Storage*/}
                                      {/*    </NavLink>*/}
                                      {/*</li>*/}
                    <AdminConsumer>
                      {({ isAdmin }) =>
                        isAdmin === true ? (
                          <li id="Admin" className="o-header-item">
                            <NavLink className="o-header-link" to="/admin">
                              Admin
                            </NavLink>
                          </li>
                        ) : null
                      }
                    </AdminConsumer>
                    <li id="WhatsNew" className="o-header-item">
                        <NavLink className='o-header-link' to="/whatsnew" exact>
                          Discover What's New
                        </NavLink>
                    </li>
                  </ul>
                  <ul className="o-header-actions">
                    <li className="o-header-dropdown o-header-dropdown-user">
                      <div className="o-header-user">
                        <Dropdown>
                          <Dropdown.Toggle className="o-header-user">
                            {initials}
                          </Dropdown.Toggle>
                          <Dropdown.Menu alignRight={true}>
                            <AdminConsumer>
                              {({ isAdmin }) =>
                                isAdmin === true ? (
                                  <Dropdown.Item
                                    className="o-header-link-admin"
                                    href="/admin"
                                  >
                                    Admin
                                  </Dropdown.Item>
                                ) : null
                              }
                            </AdminConsumer>
                            <Dropdown.Item
                              className="o-header-link-admin"
                              href="/whatsnew"
                            >
                              Discover What's New
                            </Dropdown.Item>
                            <Dropdown.Item href="https://services.veracity.com/EditProfile">
                              Settings
                            </Dropdown.Item>
                            <Dropdown.Item href="/account/SignOut">
                              Logout
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className="o-header-right">
                <NavLink to="/" className="o-header-product">
                  <img
                    className="logo-image"
                    src="/assets/images/EVOLVE-logo.png"
                    alt="App Logo"
                  />
                </NavLink>
                <ul className="o-header-actions">
                  <li className="o-header-action">
                    <a
                      target="_self"
                      href={searchUri ? `/account/SignIn?ReplyUrl=${searchUri}` : '/account/SignIn'}
                      className="o-header-action-btn"
                    >
                      Login
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </nav>
        )}
      </AuthConsumer>
    </header>
  );
}

export default Header;
