import React, { useState, useContext } from "react";
import "./CardList.css";
import { Row, Col } from "react-bootstrap";
import Card from "./Card";
import {MyProductsContext} from "../../Context/MyProductsContext";

function CardList(props) {
  const contextData = useContext(MyProductsContext);
  const products = contextData["myProducts"]["products"];
  const clientProducts=products[props.client.name]
  const services = clientProducts[props.serviceCategory];

  return (services.length > 0 ? (
    <Row>
      {services.map((service, key) => (
        <div key={key} className="col-xl-6">
          <Card service={service} />
        </div>
      ))}
    </Row>
  ) : null)
}
export default CardList;
