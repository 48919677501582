import React, { useState, useEffect } from "react";
import Chip from "@material-ui/core/Chip";
import "./UpdateApprovedUserModal.css";
import Select from "react-select";
import { Container, Row, Col, Modal, Form, Button } from "react-bootstrap";
import { Subscriptions } from "@material-ui/icons";

function UpdateApprovedUserModal(props) {

  return (
    <>
      {props.user ?

        <Modal size="lg" show={props.show} onHide={props.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form id="approved-users-form" onSubmit={props.handleSubmit}>
              <Row>
                <Form.Group as={Col} controlId="displayName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={props.user.displayName}
                    disabled
                  ></Form.Control>
                </Form.Group>

                <Form.Group as={Col} controlId="title">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={props.user.title}
                  ></Form.Control>
                </Form.Group>

                <Form.Group as={Col} controlId="company">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={props.user.company}
                  ></Form.Control>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    defaultValue={props.user.email}
                    disabled
                  ></Form.Control>
                </Form.Group>
                <Form.Group as={Col} controlId="userType">
                  <Form.Label>User Type</Form.Label>
                  <Form.Control as="select" defaultValue={props.user.userType} >
                    <option>DNV Staff</option>
                    <option>Distributor</option>
                    <option>Utility Staff</option>
                    <option>Contractor</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} controlId="role">
                  <Form.Label>Account Type</Form.Label>
                  <Form.Control as="select" defaultValue={props.user.role}>
                    <option>Admin</option>
                    <option>User</option>
                  </Form.Control>
                </Form.Group>
              </Row>
              <Row>
                <Col>
                  <h5>User Group Access</h5>
                  <hr />
                </Col>
              </Row>
              {/* <Row>
          
            <Form.Group as={Col} controlId="client">
              <Form.Label>Client</Form.Label>
              <Form.Control as="select" defaultValue={props.clients.length>0 ? props.clients[0].name: null} onChange={props.handleClientChange}>
              {props.clients.length>0 ? 
              <>
                {props.clients.map(client => (
                  <option key={client.id}>{client.name}</option>
                )) }
                </>
                : null
              }
                
              </Form.Control>
            </Form.Group>
          </Row> */}
              <Row>
                <Col className="col-12">
                  {props.availableOptions && props.clients.length > 0 ?
                    <Select
                      className="dropdown"
                      placeholder="Select Option"
                      options={props.availableOptions}
                      value={props.availableOptions.length > 0 ? props.availableOptions.filter((obj) =>
                        props.selectedOptions.includes(obj.value)
                      ): []} // set selected values
                      onChange={props.handleChange}
                      isMulti
                      isClearable
                    /> :null
                  }


                </Col>
                {props.isValid ? null:
                  <Col className="col-12">
                    <div className="alert alert-danger" role="alert">
                      User should have at least one subscribed service
                    </div>
                  </Col>
                }
              </Row>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={props.handleClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" form="approved-users-form">
              {props.buttonText}
            </Button>
          </Modal.Footer>
        </Modal>:null}
    </>
  );
}

export default UpdateApprovedUserModal;
