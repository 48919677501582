import React, { useState, useEffect, Component, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import Pagination from "@material-ui/lab/Pagination";
import { PlayCircleFilled } from '@material-ui/icons';
import axios from 'axios';
import SideNav from "../../Components/SideNav";
import { MyProductsProvider } from "../../Context/MyProductsContext";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Lightbox from 'react-image-lightbox';
import './WhatsNew.css';
import 'react-image-lightbox/style.css';
import parse from 'html-react-parser';

function WhatsNew() {
  const [selectedTab, setSelectedTab] = useState();
  const [posts, setPosts] = useState([]);
  const [bannerPost, setBannerPost] = useState();
  const [categories, setCategories] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [dates, setDates] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [pageofPosts, setPageofPosts] = useState([]);
  const itemsPerPage = 10;
  const [filtering, setFiltering] = useState(false);

  useEffect(() => {
    axios
      .get("/api/v1/Products/My", { withCredentials: true })
      .then(function (response) {
        if(response.data.clients.length > 0){
        setSelectedTab(response.data.clients[0].name);
      }
      })
      .catch(function (error) {
        // handle error
        console.log(error.response);
      });
  }, []);

  useEffect(() => {
    var livePostList = [];
    axios
       .get("/api/v1/Post/Active")
       .then((response) => {
          response.data.posts.forEach(post => {
            if (post.isBanner==true) {
              setBannerPost(post);
            }
            else {
              livePostList.push(post);
              let monthYear = formatDateTime(post.createdOn)
              setDates(dateArr => [...dateArr.filter(e => e.value!==monthYear), 
                {"value": monthYear, "label": monthYear, "reference": post.createdOn}]
                .sort((a, b) => b.reference.localeCompare(a.reference)));
            }
          });
          setPosts(livePostList);
       })
      .catch(function (error) {
        console.log(error.response);
      });
  }, []);

  useEffect(() => {setPageofPosts(getCurrentPagePost());}, [posts, filtering]);

   useEffect(() => {
     axios
     .get("api/v1/ProductCategories/All", { withCredentials: true })
     .then(function (response) {
       if (response.data.categories.length > 0) {
        response.data.categories.forEach(category => {
          setCategories(catArray => [...catArray, {"value": category.name, "label": category.name}]);
        });
       };
     })
     .catch (function (error) {
      console.log(error.response);
    });
   }, []);

   function PostCard(props) {
     const post = props.post;
     const postkey = props.postkey;
     const [isOpen, setIsOpen] = useState(false);
     const [isExpand, setIsExpand] = useState(false);

     function toggleExpand() {
        setIsExpand(!isExpand);
    }

     const dotdotdot = (obj) => {
       let charNums = parseInt(post.previewChars);
       let tags = new Array();
       let tag = "";
       let result = "";
       for(let index = 0; index < obj.length; index++){
           let char = obj[index];
           if(char == "<"){
              tag = "";
              let removed = false;
              let yindex = index + 1;
              for(yindex; yindex < obj.length; yindex++){
                let tagChar = obj[yindex];
                if(tagChar == ">"){
                  break;
                }
                else if (tagChar == "/"){
                  removed = true;
                }
                else{
                  tag += obj[yindex];
                }
              }
              if(removed){
                let findIndex = tags.indexOf(tag);
                tags.splice(findIndex, 1);
                result += "</"+tag+">";
              }
              else{
                tags.push(tag);
                result += "<"+tag+">";
              }
              index = yindex;
           }
           else{
              result += char;
              charNums--;
           }
           if(charNums == 0){
              result += "<span>....</span>";
              for(let tagIndex = tags.length - 1; tagIndex >= 0; tagIndex--){
                result += "</"+ tags[tagIndex] + ">";
              }
              break;
           }
       }
       //console.log(result);
       return result;
     }

     const shortSummary = dotdotdot(post.summary);
      return (
      <>
      <Row xs={1} md={3} className="d-flex justify-content-md-center posts-zone m-5" key={postkey}>
      <Card className="post-texts">
      <Card.Body className="post-display ml-0">
        <Card.Title className="post-title">{post.title}</Card.Title>
        {/* <Card.Text className="post-summary summary"> */}
        <div className='post-summary summary card-text'>
          {parse(isExpand ? post.summary : shortSummary)}
          {/* {isExpand ? post.summary : `${post.summary.substring(0, parseInt(post.previewChars))}...`} */}
        </div>          
        {/* </Card.Text> */}
        <Card.Link type="button" onClick={toggleExpand}>
          {isExpand ? <>Show Less &lt;&lt;</> :<>Read More &gt;&gt;</>}
        </Card.Link>
      </Card.Body>
    </Card>
    <Card className="post-image">
        <Card.Img className="img-fluid" src={post.imageUrl} onClick={() => setIsOpen(true)} rounded="true" />
    </Card>   
    <Card className="post-date">
      <Card.Body className="h5 d-flex align-items-center">{formatDateTime(post.createdOn)}</Card.Body>
    </Card>
    </Row>
    {isOpen && (<Lightbox mainSrc={post.imageUrl} onCloseRequest={() => setIsOpen(false)} />)}
    </>
     )
   }

   function BannerCard(props) {
    const post = props.post;
    const [isExpand, setIsExpand] = useState(false);
        
    function toggleExpand() {
      setIsExpand(!isExpand);
    }

    return (
      <Card className="banner-card">
      <Card.Img className="banner-image" src={post.imageUrl}/>
      <Card.ImgOverlay>
        <Card.Title className="banner banner-title">{post.title}</Card.Title>
        <Card.Text className="banner banner-summary summary">
          {isExpand ? post.summary : `${post.summary.substring(0, parseInt(post.previewChars))}...`}
        </Card.Text>
        <Card.Link className="banner banner-link" type="button" onClick={toggleExpand}>
          {isExpand ? <>Show Less &lt;&lt;</> :<>Read More &gt;&gt;</>}
        </Card.Link>
        <Card.Text className="banner banner-date">{formatDateTime(post.createdOn)}</Card.Text>
      </Card.ImgOverlay>
    </Card>
    )
   }

   
   function formatDateTime(datetime) {
     let date = new Date(datetime);
     let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
     let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
     return `${month} ${year}`;
   }

   function onSelectOptionsChange(e) {
    if (e) 
      {setSelectedFields(e.map(item => item.value));}
     else
      setSelectedFields([]);
  }; 

  function onSelectDatesChange(e) {
    if (e)
     {setSelectedDates(e.map(item => item.value));}
    else
     setSelectedDates([]);
  }

  function onChangePage(event, value) {
    setPage(value);
    setPageofPosts(getCurrentPagePost()); 
  }

  function getCurrentPagePost() {
    const start = (page-1)*itemsPerPage;
    const end = start+itemsPerPage;
    var curPosts=[];
    posts.forEach((post) => {
      if ((post.category.some(r => selectedFields.indexOf(r)>=0) || !selectedFields.length) 
      && (selectedDates.indexOf(formatDateTime(post.createdOn))>=0 || !selectedDates.length)) {
        curPosts.push(post);
      }});
    setTotalPages(Math.ceil(curPosts.length/itemsPerPage));
    return curPosts.sort((a, b) => b.createdOn.localeCompare(a.createdOn)).slice(start, end);
  }

  const dotdotdot = (post) => {
    let obj = post.summary;
    let charNums = parseInt(post.previewChars);
    let tags = new Array();
    let tag = "";
    let result = "";
    for(let index = 0; index < obj.length; index++){
        let char = obj[index];
        if(char == "<"){
           tag = "";
           let removed = false;
           let yindex = index + 1;
           for(yindex; yindex < obj.length; yindex++){
             let tagChar = obj[yindex];
             if(tagChar == ">"){
               break;
             }
             else if (tagChar == "/"){
               removed = true;
             }
             else{
               tag += obj[yindex];
             }
           }
           if(removed){
             let findIndex = tags.indexOf(tag);
             tags.splice(findIndex, 1);
             result += "</"+tag+">";
           }
           else{
             tags.push(tag);
             result += "<"+tag+">";
           }
           index = yindex;
        }
        else{
           result += char;
           charNums--;
        }
        if(charNums == 0){
           result += "<span>....</span>";
           for(let tagIndex = tags.length - 1; tagIndex >= 0; tagIndex--){
             result += "</"+ tags[tagIndex] + ">";
           }
           break;
        }
    }
    //console.log(result);
    return result;
  }

  const search = () => {
    setFiltering(!filtering);
  }

  const playVideo = (id, e) => {
    let videoObj = document.getElementById(id);
    if(videoObj){
      try{
        videoObj.className = "play";
        videoObj.setAttribute("controls", "controls")
        let playPromise = videoObj.play();
        playPromise && playPromise.then(() => {}).catch((e) => {})
      }catch{}      
    }
  }

  return (
    <MyProductsProvider>      
      <>
        <div className="container">
          <div className="row h-100 flex-nowrap">
            <div className="col pb-5 px-xl-5 mx-auto">
              <h1 className="text-center my-5">What's New</h1>
              <div className="row mx-md-1 mx-lg-3 searchfilter">
                <div className="col-md-6 pr-md-0 mb-4">
                  <Select
                    isMulti
                    name="categories"
                    options={categories}
                    closeMenuOnSelect={false}
                    components={makeAnimated}  
                    onChange={onSelectOptionsChange}                     
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="All categories"
                    />
                </div>
                <div className="col-md-4 pr-md-0 mb-4">
                  <Select
                    isMulti
                    name="dates"
                    options={dates}
                    closeMenuOnSelect={false}
                    components={makeAnimated}
                    onChange={onSelectDatesChange}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="All dates"                          
                      />
                </div>
                <div className="col-md-2 mb-4">
                  <Button className="btn btn-primary" onClick={search} >Filter</Button>
                </div>
              </div>
              <div className="row pt-2">
                <Col>
                  <div className='posts'>
                    {pageofPosts.map((post, postkey) => (
                      <>
                        <a href={`posts/${post.id}`} className='post' key={postkey}>
                            {
                              post.videoUrl ? (<>
                                <div className='postVideo'>                                                               
                                  <video id={postkey} poster={post.imageUrl}  muted autoPlay={false} preload='none' >
                                    <source src={post.videoUrl}/>
                                  </video>
                                  <div id={`overlay${postkey}`} onClick={e => playVideo(postkey, e)}><PlayCircleFilled fontSize='large'/></div>
                                </div>
                              </>) : 
                                        <span className='image' style={{
                                            backgroundImage: "url(" + (post.imageUrl.indexOf('Compressed_') >= 0 ? post.imageUrl.replace('.', '_s.') : post.imageUrl) + ")"
                                        }}></span>
                            }
                            <div className='content'>
                              <h3>{post.title}</h3>
                              <div className='description'>
                                {parse(dotdotdot(post))}
                              </div>                                    
                              <div className='operations'>
                                <div style={{display: 'inline-block', float: 'left'}}><Link to={`posts/${post.id}`}>Learn more</Link></div>
                                <div style={{display: 'inline-block', float: 'right', color: 'black'}}>{post.createdOn && new Date(post.createdOn).toLocaleDateString()}</div>
                                
                              </div>
                            </div>
                          </a>
                      </>
                    ))}
                  </div>
                </Col>
              </div>
            </div>
          </div>
        </div>
      </>
  </MyProductsProvider>
  );
};

export default WhatsNew;