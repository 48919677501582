import React, { useState } from "react";
import {Modal, Button } from "react-bootstrap";
function DeleteModal(props) {
    return (
        <Modal size="lg" show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete {props.type}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this {props.type}?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={props.handleDelete}>Delete</Button>
        </Modal.Footer>
      </Modal>
    )
}

export default DeleteModal
