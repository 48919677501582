import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import Routes from "../Routes";
import Navbar from "../Navbar";
import "./AuthenticatedApp.css";
import axios from "axios";
import { Route } from "react-router-dom";
import Loader from "react-loader-spinner";
import { IsApprovedConsumer } from "../../Context/AdminContext";
import UnapprovedHome from "../../Views/UnapprovedHome";

function AuthenticatedApp() {
  const [validateURL, setValidateURL] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("/api/v1.0/Veracity/ValidatePolicy")
      .then((response) => {
        setLoading(false);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          window.location.href = "/account/SignOut";
        } else if (error.response.status === 406) {
          setValidateURL(error.response.data);
        } else {
          console.log(error.response);
        }
      });
  }, []);

  return (
    <div>
      {validateURL ? (
        <Route
          path="/"
          component={() => {
            window.location.href = validateURL;
            return null;
          }}
        />
      ) : loading ? (
        <div className="nothing-display">
          <Loader type="Oval" color="#0F204B" height={100} width={100} />
        </div>
        ) : (
            <IsApprovedConsumer>
              {({ isApproved }) => (
                <div className="wrapper">
                  <Header />
                  {isApproved ? < Routes /> : < UnapprovedHome /> }
                  <Footer />
                </div>
              )}
            </IsApprovedConsumer>
      )}
    </div>
  );
}

export default AuthenticatedApp;
