import React, { useState, useEffect } from "react";
import axios from "axios";
import { Tab, Nav, Container, Row, Table, InputGroup } from "react-bootstrap";
import "./Users.css";
import PendingUsersTable from "../../../Components/Tables/PendingUsersTable";
import ApprovedUsersTable from "../../../Components/Tables/ApprovedUsersTable";
import UpdateApprovedUserModal from "../../../Components/Modals/UpdateApprovedUserModal";
import AddApprovedUserModal from "../../../Components/Modals/AddApprovedUserModal";
import UpdatePendingUserModal from "../../../Components/Modals/UpdatePendingUserModal";
import DeleteApprovedUserModal from "../../../Components/Modals/DeleteApprovedUserModal";
import AddMultipleUsersModal from "../../../Components/Modals/AddMultipleUsersModal";
import Loader from "react-loader-spinner";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { ToastContainer, toast } from 'react-toastify';
import VAPUsersTable from "../../../Components/Tables/VAPUsersTables";

function Users() {
  const emptyUser = {
    id: null,
    displayName: "",
    firstName: "",
    lastName: "",
    title: "",
    company: "",
    email: "",
    userType: "DNV Staff",
    role: "User",
    subscriptions: [],
  };

  const [isBusy, setBusy] = useState(true);
  const [approvedUsers, setApprovedUsers] = useState([]);
  const [pendingUsers, setPendingUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(emptyUser);
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const [clients, setClients] = useState([]);
  const [selectedClientname, setSelectedClientname] = useState("");

  const [availableOptions, setAvailableOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState("");

  const [services, setServices] = useState([]);

  const [userGroups, setUserGroups] = useState([]);
  // const [selectedOption, setSelectedOption] = useState([]);
  const [updatePendingUsersShow, setUpdatePendingUsersShow] = useState(false);
  const [ApprovedUsersShow, setApprovedUsersShow] = useState(false);
  const [addApprovedUsersShow, setAddApprovedUsersShow] = useState(false);
  const [deleteApprovedUsersShow, setDeleteApprovedUsersShow] = useState(false);
  const [addMultipleUsersModalShow, setAddMultipleUsersModalShow] = useState(
    false
  );
  const [checkedPendingUsers, setCheckedPendingUsers] = useState([]);
  const [title, setTitle] = useState();
  const [buttonText, setButtonText] = useState();

  const [csvFile, setCsvFile] = useState(null);
  const [csvBlobUrl, setCsvBlobUrl] = useState("/api/v1/Files/excels/BatchUploadUser.xlsx");

  const [vapUsers, setVAPUsers] = useState([]);
  const [updateVAPUsers, setUpdateVAPUsers] = useState(false);
  const [isVAPUserLoading, setIsVAPUserLoading] = useState(false);

  //===============================================================================================
  //
  //===============================================================================================
  const allClientsRequest = axios.get("/api/v1/Client/All", {
    withCredentials: true,
  });
  const allServicesRequest = axios.get("/api/v1/Products/All/Subscribed", {
    withCredentials: true,
  });
  const allUsergroupsRequest = axios.get("/api/v1/UserGroup/All", {
    withCredentials: true
  })

  // useEffect(() => {
  //   axios
  //     .get("/api/v1/Files/Config/Excel", { withCredentials: true })
  //     .then(function (response) {
  //       setCsvBlobUrl(response.data.blobUrl+"BatchUploadUser.xlsx");
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       console.log(error.response);
  //     });
  // }, []);

  useEffect(() => {
    axios
      .all([allClientsRequest, allServicesRequest, allUsergroupsRequest])
      .then(
        axios.spread((...responses) => {
          setClients(responses[0].data.clients);
          setServices(responses[1].data);
          handleUserGroups(responses[2].data.userGroups);
          if (responses[0].data.clients.length > 0) {
            setSelectedClientname(responses[0].data.clients[0].name);
          }
        })
      )
      .then(
        axios
          .get("/api/v1/User/PendingUsers", { withCredentials: true })
          .then(function (response) {
            setPendingUsers(response.data.users);
          })
      )
      .then(
        axios
          .get("/api/v1/User/ApprovedUsers", { withCredentials: true })
          .then(function (response) {
            setApprovedUsers(response.data.users);
          })
      )
      .finally(setBusy(false))
      .catch(function (error) {
        // handle error
        console.log(error.response);
      });
  }, []);

  useEffect(() => {
    getVapUsers();
  }, [updateVAPUsers])

  const handleUserGroups = (data) => {
    let items = [];
    data.forEach(item => {
      items.push({
        id: item.id,
        name: item.name,
        client: item.clientName,
        clientId: item.clientId,
        clientProductIds: item.clientProductIds,
        services: item.clientProductNames
      })
    });
    setUserGroups(items);
    setAvailableOptions(getAvailableUserGroupsOptions(items));
  }

  //=============================================================================================
  //  Functions
  //=============================================================================================
  const getVapUsers = () =>{
    setIsVAPUserLoading(true);
      axios
          .get("/api/v1/User/VAPUsers", { withCredentials: true })
          .then(function (response) {
            response && response.data && response.data.users && setVAPUsers(response.data.users);
            setIsVAPUserLoading(false);  
          })
          .catch((error) => {
            setVAPUsers([]);
            setIsVAPUserLoading(false);
          })
  }

  ////////////////////////  Show Close Modals
  const handleAddApprovedUsersShow = (vapuser) => {
    //setSelectedClientname(clients.length > 0 ? clients[0].name : '');
    setAddApprovedUsersShow(true);
    if(vapuser){
      vapuser.displayName = vapuser.name;
      vapuser.userType = "DNV Staff";
      vapuser.role = "User";
      setSelectedUser(vapuser);
    }
    else{
      setSelectedUser(emptyUser);
    }
    setAvailableOptions(getAvailableUserGroupsOptions());
    setSelectedOptions([]);
    setTitle("Add User");
    setButtonText("Add");
    // setSelectedOption([]);
    setIsValid(true);
  };
  const handleUpdateApprovedUsersShow = (user) => {
    //setSelectedClientname(clients.length > 0 ? clients[0].name : '');
    setApprovedUsersShow(true);
    let item = user.user ? user.user : user;  
    setSelectedUser(item);
    setAvailableOptions(getAvailableUserGroupsOptions());
    setSelectedOptions(getUserGroupIds(item));
    setTitle("Update User");
    setButtonText("Update");
    setIsValid(true);
  };

  const handleDeleteApprovedUsersShow = (user) => {
    setSelectedUser(user);
    setDeleteApprovedUsersShow(true);
    console.log(user);
  };

  const handleAddMultipleUsersShow = () => {
    setSelectedClientname(clients.length>0 ? clients[0].name : '');
    setSelectedUser(emptyUser);
    setAvailableOptions(getAvailableUserGroupsOptions());
    setSelectedOptions([]);
    setIsValid(true);
    setAddMultipleUsersModalShow(true);
  };

  const handleExport = () => {
    setIsLoading(true);
    axios({
      method: "get",
      url: "/api/v1/User/Export/All",
      responseType: "arraybuffer"
    }).then((response) => {
      console.log(response.data);
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/octet-stream" })
      );
      link.download = "Users.xlsx";

      document.body.appendChild(link);

      link.click();
      setTimeout(function () {
        window.URL.revokeObjectURL(link);
      }, 200);
      setIsLoading(false);
    }).catch(function (error) {
      setIsLoading(false);
    });
  }

  const handleExportVAPUsers = () => {
    setIsLoading(true);
    axios({
      method: "get",
      url: "/api/v1/User/ExportVAPUsers/All",
      responseType: "arraybuffer"
    }).then((response) => {
      console.log(response.data);
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/octet-stream" })
      );
      link.download = "VAPUsers.xlsx";

      document.body.appendChild(link);

      link.click();
      setTimeout(function () {
        window.URL.revokeObjectURL(link);
      }, 200);
      setIsLoading(false);
    }).catch(function (error) {
      setIsLoading(false);
    });
  }

  const handleUpdatePendingUsersClose = () => setUpdatePendingUsersShow(false);
  const handleApprovedUsersClose = () => setApprovedUsersShow(false);
  const handleAddApprovedUsersClose = () => setAddApprovedUsersShow(false);
  const handleDeleteApprovedUsersClose = () =>
    setDeleteApprovedUsersShow(false);
  const handleAddMultipleUsersClose = () => setAddMultipleUsersModalShow(false);

  function getAvailableOptions(clientname) {
    var output = {};
    if (clientname !== "") {
      let clientServices = services[clientname];
      var clientServicesDict = [];
      if (clientServices) {
        for (let cs of clientServices) {
          clientServicesDict.push({ value: cs.id, label: cs.name });
        }
        output = clientServicesDict;
      }

      return output;
    } else {
      return output;
    }
  }

  function getAvailableUserGroupsOptions(items) {
    var output = {};
    var clientServicesDict = [];
    var items = userGroups && userGroups.length > 0 ? userGroups : items;
    if (items) {
      for (let cs of items) {
        clientServicesDict.push({ value: cs.id, label: cs.name });
      }
      output = clientServicesDict;
    }
    return output;
  }

  const handleClientChange = (event) => {
    console.log(event);
    setSelectedClientname(event.target.value);
    setAvailableOptions(getAvailableOptions(event.target.value));
    setSelectedOptions(
      getUserClientSubscriptionsIds(selectedUser, event.target.value)
    );
  };

  function findServicesById(id) {
    let selectedservices = [];
    let usergrouplist = userGroups ? userGroups.filter(ele => ele.id === id) : [];
    let usergroup = usergrouplist && usergrouplist.length > 0 && usergrouplist[0];
    if (usergroup && services) {
      let allservices = [].concat(...Object.values(services));
      var selectedservice = allservices.filter(ele => usergroup.clientProductIds.includes(ele.id));
      selectedservice && selectedservices.push(...selectedservice);
    }
    return selectedservices;
  }
  /*
    Get selected user subscriptions
  */
  function getUserSubscriptionsIds() {
    var ids = [];
    for (let s of selectedUser.subscriptions) {
      ids.push(s.id);
    }
    return ids;
  }

  function getUserSubscriptionsIdsString() {
    var ids = [];
    for (let s of selectedUser.subscriptions) {
      console.log("selectedUser for bug:", selectedUser);
      ids.push(s.id);
    }
    if (ids.length > 0) {
      return ids.join(",");
    } else {
      return "";
    }
  }
  function getAllBut(user, clientname) {
    let userClientSubscriptionsIds = getUserClientSubscriptionsIds(
      user,
      clientname
    );
    console.log(
      "userClientSubscriptionsIds from getallbut:",
      userClientSubscriptionsIds
    );
    let userSubscriptionsids = getUserSubscriptionsIds(user);
    console.log("userSubscriptionsids from getallbut:", userSubscriptionsids);
    let allBut = userSubscriptionsids.filter(function (el) {
      return userClientSubscriptionsIds.indexOf(el) < 0;
    });
    console.log("allbut:", allBut);
    return allBut;
  }
  function mergeUnique(a, b) {
    let c = a.concat(b);
    let d = [];
    for (let item of c) {
      if (d.indexOf(item) == -1) {
        d.push(item);
      }
    }
    return d;
  }
  function getUserClientSubscriptionsIds(user, clientname) {
    var allSubscriptions = user.subscriptions;
    var subscriptionsIds = [];
    if(allSubscriptions.length>0){
      for (let s of allSubscriptions) {
        if (s.clientName == clientname) {
          subscriptionsIds.push(s.id);
        }
      }
    }

    return subscriptionsIds;
  }

  function getUserGroupIds(user) {
    console.log(user);
    let usergroups = user.userGroups ? user.userGroups.map(x => x.id) : [];
    return usergroups;
  }

  const handleOptionChange = (event) => {
    //var subscriptionsIds = getAllBut(selectedUser, selectedClientname);
    let options = Array.isArray(event) ? event.map((x) => x.value) : [];
    //let allOptions = mergeUnique(subscriptionsIds, options);
    let newSubscriptions = [];
    for (let o of options) {
      let newServices = findServicesById(o);
      newSubscriptions = Array.from(new Set([...newSubscriptions, ...newServices]));
    }
    setSelectedOptions(options);

    let userGroups = options.map(o => { return { id: o } });

    setSelectedUser({ ...selectedUser, subscriptions: newSubscriptions, userGroups });
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const handleUpdatePendingUsersShow = (user) => {
    setUpdatePendingUsersShow(true);
    //setAvailableOptions(getAvailableUserGroupsOptions());
    setSelectedOptions(getUserGroupIds(user));
    setSelectedUser(user);
  };
  const handleCheckbox = (row, isSelect) => {
    if (isSelect) {
      let items = checkedPendingUsers;
      let index = checkedPendingUsers.findIndex(
        (element) => element.id == row.id
      );
      if (index < 0) {
        items.push(row);
        setCheckedPendingUsers(items);
      }
      else {
        items = items.slice(index, 1);
        items.push(row);
        setCheckedPendingUsers(items);
      }
    } else {
      let index = checkedPendingUsers.findIndex(
        (element) => element.id == row.id
      );
      let items = checkedPendingUsers;
      items.splice(index, 1);
      setCheckedPendingUsers(items);
    }
    console.log("row", row);
    console.log("isSelect", isSelect);
    console.log("checked users", checkedPendingUsers);
  };

  const handleDeny = () => {
    var items = pendingUsers;
    var checkedItems = checkedPendingUsers;
    // console.log('items:',items)
    checkedItems.forEach(function (user) {
      var index = items.findIndex((element) => element.id == user.id);
      if (index > -1) {
        setIsLoading(true);
        var formData = new FormData();
        formData.append("userId", items[index].id);
        axios
          .delete("/api/v1/User/Delete?userId=" + items[index].id, {
            withCredentials: true,
          })
          .then((response) => {
            items.splice(index, 1);
            setPendingUsers(items);
            setIsLoading(false);
          });
      }
    });
    setCheckedPendingUsers([]);
  };

  const handleApprove = () => {
    var pendingItems = pendingUsers
    var checkedItems = checkedPendingUsers
    
    var users = pendingItems.filter(x => checkedItems.find(y => y.id === x.id)) || []
    if(users.length > 0)
    {
      setIsLoading(true);
      let parametersStr = "";
      users.forEach((x, i) => {
        parametersStr += `${ i > 0 ? '&' : ''}userIds=${x.id}`
      })
      axios
      .post("/api/v1/User/Activate?" + parametersStr)
      .then((response) => {
        setIsLoading(false)
        setPendingUsers(pendingItems.filter(x => !checkedItems.find(y => y.id === x.id)))
        setCheckedPendingUsers([])
    });
    }
  };

  const handlePendingUserSubmit = (event) => {
    event.preventDefault();
    let items = pendingUsers;
    const index = pendingUsers.findIndex(
      (element) => element.id == selectedUser.id
    );

    var updatedUser = {
      id: selectedUser.id,
      displayName: event.target.displayName.value,
      email: event.target.email.value,
      title: event.target.title.value,
      company: event.target.company.value,
      userType: event.target.userType.value,
      role: event.target.role.value,
      subscriptions: selectedUser.subscriptions,
      userGroups: selectedUser.userGroups
    };
    setIsLoading(true);
    var formData = new FormData();
    formData.append("id", updatedUser.id);
    formData.append("title", updatedUser.title);
    formData.append("company", updatedUser.company);
    formData.append("userType", updatedUser.userType);
    formData.append("role", updatedUser.role);
    formData.append(
      "clientProductIds",
      JSON.stringify(getUserSubscriptionsIds())
    );
    formData.append(
      "userGroupIds",
      JSON.stringify(updatedUser.userGroups ? updatedUser.userGroups.map(x => x.id) : [])
    );
    console.log("selectedOptions:", JSON.stringify(getUserSubscriptionsIds()));
    console.log("user at submit:", selectedUser);

    axios({
      method: "post",
      url: "/api/v1/User/Update",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
      //   withCredentials: true,
    }).then((response) => {
      items[index] = updatedUser;
      setPendingUsers(items);
      setUpdatePendingUsersShow(false);
      setIsLoading(false);
    });
  };

  const handleVAPUsersSync = (email) => {
    if(vapUsers && vapUsers.length > 0){
      if(email){
        const index = vapUsers.findIndex(
          (element) => element.email == email
        );
        index > 0 && setUpdateVAPUsers(!updateVAPUsers);
      }
      else{
        setUpdateVAPUsers(!updateVAPUsers);
      }
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  const handleAddApprovedUserSubmit = (event) => {
    event.preventDefault();
    if (getUserSubscriptionsIdsString()) {
      let items = approvedUsers;
      var item = {
        firstName: event.target.firstName.value,
        lastName: event.target.lastName.value,
        email: event.target.email.value,
        role: event.target.role.value,
        company: event.target.company.value,
        userType: event.target.userType.value,
        title: event.target.title.value,
        subscriptions: selectedUser.subscriptions,
        userGroups: selectedUser.userGroups
      };
      setIsLoading(true);
      var formData = new FormData();
      formData.append("firstName", item.firstName);
      formData.append("lastName", item.lastName);
      formData.append("email", item.email);
      formData.append("role", item.role);
      formData.append("company", item.company);
      formData.append("userType", item.userType);
      formData.append("title", item.title);
      formData.append(
        "clientProductIds",
        JSON.stringify(getUserSubscriptionsIds())
      );
      formData.append("userGroupIds", JSON.stringify(item.userGroups.map(x => x.id)));
      axios({
        method: "post",
        url: "/api/v1/User/New",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => {
        if (response.status == '400') {
          setAddApprovedUsersShow(false);
          setIsLoading(false);
          toast.error("Something went wrong. Please check if the user already exists.")
        }
        else{
          items.push({
            id: response.data.id,
            displayName: response.data.displayName,
            email: response.data.email,
            role: response.data.role,
            company: response.data.company,
            userType: response.data.userType,
            title: response.data.title,
            subscriptions: response.data.subscriptions,
            userGroups: response.data.userGroups
          });

          setApprovedUsers(items);
          handleVAPUsersSync();          
          setAddApprovedUsersShow(false);          
            setIsLoading(false);

          }

      }).catch(function (error) {
        // handle error
        setAddApprovedUsersShow(false);
        setIsLoading(false);
        toast.error(error.response.data || "Something went wrong");
      });
    } else {
      setIsValid(false);
    }
  };

  const handleDelete = () => {
    let items = approvedUsers;
    const index = approvedUsers.findIndex(
      (element) => element.id == selectedUser.id
    );
    setIsLoading(true);
    axios
      .delete("/api/v1/User/Delete?userId=" + selectedUser.id, {
        withCredentials: true,
      })
      .then((response) => {
        items.splice(index, 1);
        setApprovedUsers(items);
        setDeleteApprovedUsersShow(false);
        setIsLoading(false);
        getVapUsers();
      })
      .catch((ex) => {
        if (ex.response.status == 400) {
          items.splice(index, 1);
        }
        setApprovedUsers(items);
        setDeleteApprovedUsersShow(false);
        setIsLoading(false);
        getVapUsers();
        toast.error(ex.response.data || ex.message);
      });
  };

  const handleApprovedUserSubmit = (event) => {
    event.preventDefault();
    console.log("from update", getUserSubscriptionsIdsString());
    if (getUserSubscriptionsIdsString()) {
      let items = approvedUsers;
      const index = approvedUsers.findIndex(
        (element) => element.id == selectedUser.id
      );

      if (index >= 0) {
        var item = {
          id: selectedUser.id,
          displayName: selectedUser.displayName,
          email: selectedUser.email,
          role: event.target.role.value,
          company: event.target.company.value,
          userType: event.target.userType.value,
          title: event.target.title.value,
          clientProductIds: selectedOptions,
          subscriptions: selectedUser.subscriptions,
          userGroups: selectedUser.userGroups
        };
        setIsLoading(true);
        var formData = new FormData();
        formData.append("id", item.id);
        formData.append("role", item.role);
        formData.append("company", item.company);
        formData.append("userType", item.userType);
        formData.append("title", item.title);
        formData.append(
          "clientProductIds",
          JSON.stringify(getUserSubscriptionsIds())
        );
        formData.append(
          "userGroupIds",
          JSON.stringify(item.userGroups.map(x => x.id))
        );
        console.log(
          "clientProductIds:",
          JSON.stringify(getUserSubscriptionsIds())
        );
        console.log("user at submit:", selectedUser);
        //   console.log("formData:",formData.get("clientProductIds"))
        axios({
          method: "post",
          url: "/api/v1/User/Update",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
          // withCredentials: true,
        }).then((response) => {
          items[index] = item;
          setApprovedUsers(items);
          handleVAPUsersSync(response.data.email);
          setApprovedUsersShow(false);
          setIsLoading(false);
        }).catch(function (ex) {
          // handle error
          setApprovedUsersShow(false);
          setIsLoading(false);
          toast.error("Something went wrong " + ex.message);
        });
      }
    } else {
      setIsValid(false);
    }
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const handleFileChange = (event) => {
    setCsvFile(event.target.files[0]);
  };

  function userbyId(element) {
    return element.id == this.id;
  }
  const handleAddMultipleUsersSubmit = (event) => {
    event.preventDefault();
    var items=approvedUsers;
    console.log("submitted at add click:",getUserSubscriptionsIds())
    if (getUserSubscriptionsIdsString()) {
      setIsLoading(true);
      var formData = new FormData();
      formData.append("file", csvFile);
      formData.append(
        "clientProductIds",
        JSON.stringify(getUserSubscriptionsIds())
      );
      formData.append(
        "userGroupIds",
        JSON.stringify(selectedUser.userGroups ? selectedUser.userGroups.map(x => x.id) : [])
      );
      axios({
        method: "post",
        url: "/api/v1/User/BatchNew",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => {
        console.log(response.data);
        if (response.data.statusCode == 400) {
          setAddMultipleUsersModalShow(false);
          setIsLoading(false);
          let errormessage = response.data.failedUsers.map(err => `Row ${err.rowNum} - ${err.messages.join()}`).join('\n');
          toast.error("Something went wrong. \n" + errormessage);
        }
        else {
          for (let item of response.data.createdUsers){
            if(item){
              items.push(item);
            }            
          }
          for (let item of response.data.modifiedUsers) {
            if(item){
              var index = items.findIndex(userbyId, item);
              if (index >= 0) {
                items[index] = item;
              }
            }            
          }
          setApprovedUsers(items);
          setAddMultipleUsersModalShow(false);
          setIsLoading(false);

          if (response.data.failedUsers && response.data.failedUsers.length > 0) {
            let errormessage = response.data.failedUsers.map(err => `Row ${err.rowNum} - ${err.messages.join()}`).join('\n');
            toast.error(`${response.data.numOfCreatedUsers} users are uploaded. \n Something went wrong. \n ${errormessage}`);
          }
          else {
            toast.success(`${response.data.numOfCreatedUsers} users are uploaded successfully`);
          }
        }
      }).catch(function (ex) {
        console.log("batch new", ex);
        // handle error
        setAddMultipleUsersModalShow(false);
        setIsLoading(false);
        toast.error("Something went wrong \n" + ex.message);
      });
    } else {
      setIsValid(false);
    }
  };
  //=============================================================================================
  //   Render
  //=============================================================================================
  return (
    <>
      {isBusy ? null : (
        <Container>
          <ToastContainer />
          <Row className="px-3 py-4">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="large" />}
              aria-label="breadcrumb"
            >
              <Link to="/admin">Admin</Link>
              <Typography className="MuiBreadcrumbs-li-last">
                Manage Users
              </Typography>
            </Breadcrumbs>
            {/* <h4>Admin &gt; Manage Users</h4> */}
          </Row>
          <div>
            <div className="tabs">
              <Tab.Container defaultActiveKey="pending">
                <Nav variant="tabs" as="ul">
                  <Nav.Item as="li">
                    <Nav.Link eventKey="pending">Pending Users</Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link eventKey="approved">Approved Users</Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link eventKey="vap">VAP Users</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="pending">
                    {isLoading ? (
                      <Loader
                        className="nothing-display"
                        type="Oval"
                        color="#0F204B"
                        height={100}
                        width={100}
                      />
                    ) : (
                      <>
                        <div className="float-right">
                          <button
                            className="btn btn-secondary mb-2"
                            onClick={() => {
                              handleApprove();
                              //   setUpdate(!update);
                            }}
                          >
                            <i className="fas fa-check"></i> &nbsp; Approve
                          </button>
                          <button
                            className="btn btn-secondary mb-2 ml-2"
                            onClick={() => {
                              handleDeny();
                            }}
                          >
                            <i className="fas fa-ban"></i> &nbsp; Deny
                          </button>
                        </div>
                        <PendingUsersTable
                          data={pendingUsers}
                          handleCheckbox={handleCheckbox}
                          handleShow={handleUpdatePendingUsersShow.bind(this)}
                        />
                        <UpdatePendingUserModal
                          show={updatePendingUsersShow}
                          availableOptions={availableOptions}
                          selectedOptions={selectedOptions}
                          handleChange={handleOptionChange}
                          handleClose={handleUpdatePendingUsersClose}
                          handleSubmit={handlePendingUserSubmit}
                          user={selectedUser}
                          usergroups={userGroups}
                        />
                      </>
                    )}
                  </Tab.Pane>

                  <Tab.Pane eventKey="approved">
                    {isLoading ? (
                      <Loader
                        className="nothing-display"
                        type="Oval"
                        color="#0F204B"
                        height={100}
                        width={100}
                      />
                    ) : (
                      <>
                        <ApprovedUsersTable
                          data={approvedUsers}
                          handleUpdateApprovedUsersShow={
                            handleUpdateApprovedUsersShow
                          }
                          handleAddApprovedUsersShow={
                            handleAddApprovedUsersShow
                          }
                          handleDeleteApprovedUsersShow={
                            handleDeleteApprovedUsersShow
                          }
                          handleAddMultipleUsersShow={
                            handleAddMultipleUsersShow
                          }
                          handleExport={
                            handleExport
                          }
                          user={selectedUser}
                        />


                        <UpdateApprovedUserModal
                          show={ApprovedUsersShow}
                          handleClose={handleApprovedUsersClose}
                          user={selectedUser}
                          clients={clients}
                          selectedClientname={selectedClientname}
                          availableOptions={availableOptions}
                          selectedOptions={selectedOptions}
                          handleChange={handleOptionChange}
                          handleClientChange={handleClientChange}
                          title={title}
                          buttonText={buttonText}
                          handleSubmit={handleApprovedUserSubmit}
                          isValid={isValid}
                        />

                        <AddApprovedUserModal
                          show={addApprovedUsersShow}
                          handleClose={handleAddApprovedUsersClose}
                          user={selectedUser}
                          clients={clients}
                          selectedClientname={selectedClientname}
                          availableOptions={availableOptions}
                          selectedOptions={selectedOptions}
                          handleChange={handleOptionChange}
                          handleClientChange={handleClientChange}
                          handleSubmit={handleAddApprovedUserSubmit}
                          title={title}
                          buttonText={buttonText}
                          isValid={isValid}
                        />
                        <DeleteApprovedUserModal
                          show={deleteApprovedUsersShow}
                          handleClose={handleDeleteApprovedUsersClose}
                          handleDelete={handleDelete}
                          user={selectedUser}
                        />

                        <AddMultipleUsersModal
                          show={addMultipleUsersModalShow}
                          handleClose={handleAddMultipleUsersClose}
                          clients={clients}
                          selectedClientname={selectedClientname}
                          availableOptions={availableOptions}
                          selectedOptions={selectedOptions}
                          handleChange={handleOptionChange}
                          handleClientChange={handleClientChange}
                          handleSubmit={handleAddMultipleUsersSubmit}
                          isValid={isValid}
                          handleFileChange={handleFileChange}
                          blobUrl={csvBlobUrl}
                        />
                      </>
                    )}
                  </Tab.Pane>
                  
                  <Tab.Pane eventKey="vap">
                  {isLoading || isVAPUserLoading ? (
                      <Loader
                        className="nothing-display"
                        type="Oval"
                        color="#0F204B"
                        height={100}
                        width={100}
                      />
                    ) : (
                      <>
                        <VAPUsersTable
                          data={vapUsers}
                          handleUpdateApprovedUsersShow={
                            handleUpdateApprovedUsersShow
                          }
                          handleAddApprovedUsersShow={
                            handleAddApprovedUsersShow
                          }
                          handleDeleteApprovedUsersShow={
                            handleDeleteApprovedUsersShow
                          }
                          handleAddMultipleUsersShow={
                            handleAddMultipleUsersShow
                          }
                          handleExport={
                            handleExportVAPUsers
                          }
                        />
                      </>
                    )}
                  </Tab.Pane>
                  
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </Container>
      )}
    </>
  );
}

export default Users;
