import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminContext = React.createContext();
const IsApprovedContext = React.createContext();

function AdminProvider(props) {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  useEffect(() => {
    // Account/Info
    axios
      .get("/api/v1/Account/Info", { withCredentials: true }).then(response => {
        console.log("isAdmin:", response.data)
        let isAdmin = response.data.role === "Admin";
        let isApproved = response.data.approved;
        setIsAdmin(isAdmin);
        setIsApproved(isApproved);
      })
    
  },[]);

  return (
    <AdminContext.Provider value={{ isAdmin }}>
      <IsApprovedContext.Provider value={{ isApproved}}>
        {props.children}
      </IsApprovedContext.Provider>
    </AdminContext.Provider>
  )
}

const AdminConsumer = AdminContext.Consumer;
const IsApprovedConsumer = IsApprovedContext.Consumer;

export { AdminProvider, AdminConsumer, IsApprovedConsumer };