import React, { useState, useEffect, useContext } from "react";
import "./TabContent.css";
import { Container, Row, Col } from "react-bootstrap";
import CardList from "../../Components/CardList";
import Card from "../../Components/CardList";
import { MyProductsContext } from "../../Context/MyProductsContext";
import axios from "axios";
import AvailableServiceModal from "../Modals/AvailableServiceModal";

function TabContent(props) {
  const contextData = useContext(MyProductsContext);
  const data = contextData.myProducts;
  const [availableServices, setAvailableServices] = useState([]);
  const [categories, setCategories] = useState();
  const [show, setShow] = useState(false);
  const [selectedService, setSelectedService] = useState();
  const [contactEmail, setContactEmail] = useState("");

  useEffect(() => {
    axios
      .get("/api/v1/Products/AllOnes/" + props.client.id)
      .then((response) => {
        setAvailableServices(response.data.availableProducts);
        setCategories(response.data.categories);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }, []);

  useEffect(() => {
    axios
      .get("/api/v1/Config/ContactEmail")
      .then((response) => {
        setContactEmail(response.data.contactEmail)
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }, []);

  const availableServiceModalShow = (service) =>{
    setSelectedService(service);
    setShow(true)
  }

  const availableServiceModalClose = () =>{
    setShow(false)
  }
  return (
    <div>
      {Object.keys(data).length > 0 ? (
        <Row id={props.client.name}>
          {props.client.categories.length > 0
            ? props.client.categories.map((serviceCategory, key) => (
                <div className="col-md-6" key={key}>
                  <div className="serviceCategory-text">{serviceCategory}</div>
                  <CardList
                    key={key}
                    serviceCategory={serviceCategory}
                    client={props.client}
                    serviceType="my"
                  />
                </div>
              ))
            : null}
        </Row>
      ) : null}
      
        <Row className="mt-5 mb-1 d-flex align-items-center" id={"available-" + props.client.name}>
        <div className="col-md-6">
            <h2>{Object.keys(availableServices).length > 0 && "Available Services"}</h2>
        </div>
        <div className="col-md-6 text-lg-right">
            <a className="btn btn-flat" href={"mailto:"+contactEmail}>
              <i className="fal fa-plus mr-2"></i>
              <b>Contact us to add new services</b>
            </a>
        </div>
      </Row>
      
      <Row>
        {Object.keys(availableServices).length > 0
          ? Object.keys(availableServices).map((serviceCategory, key) => (
              <div className="col-md-6" key={key}>
                <div className="serviceCategory-text">{serviceCategory}</div>
                  { categories ?
                  <div className="category-description-text">
                    {categories[key].description}
                  </div>: null
                  }
                <Row>
                  {availableServices[serviceCategory].length > 0
                    ? availableServices[serviceCategory].map((service, key) => (
                        <div key={key} className="col-xl-6">
                          <div className="card card-small services flex-row">
                            <div className="img-section">
                                <img 
                                  src={service.imageUrl}
                                  className="card-img"
                                  alt={service.name}
                                    />
                            </div>
                            <div className="card-body">
                                <div>{service.name}</div>
                                <button className="available-service-btn" onClick={()=>availableServiceModalShow(availableServices[serviceCategory][key])}>
                                    Learn more
                                </button>
                            </div>
                          </div>
                        </div>
                      ))
                    : null}
                </Row>
              </div>
            ))
          : null}
      </Row>
      <>
      {selectedService ?
      <AvailableServiceModal
      show={show}
      service={selectedService}
      handleClose={availableServiceModalClose}
    />:null
      }
      </>
      
    </div>
  );
}
export default TabContent;
