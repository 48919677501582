import React, { useState, useEffect } from "react";
import "./UpdateApprovedUserModal.css";
import { Container, Row, Col, Modal, Form, Button } from "react-bootstrap";
import PostForm from "../Forms/PostForm";

function ManagePostModal (props) {
  return (
    <>
    {props.post?
    <Modal size="lg" show={props.show} onHide={props.handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PostForm post={props.post} formType={props.formType} handleUpdatedResult={props.handleUpdatedResult} handleModalClose={props.handleModalClose}></PostForm>
      </Modal.Body>
    </Modal>
    :null}
    </>
  )
}

export default ManagePostModal;