import React, { useState, useEffect } from "react";
import axios from "axios";
import ServiceTable from "../../../Components/Tables/ServiceTable";
import { Container, Row, Col, Modal, Form, Button } from "react-bootstrap";
import ServiceModal from "../../../Components/Modals/ServiceModal";
import CategoryModal from "../../../Components/Modals/CategoryModal";
import DeleteModal from "../../../Components/Modals/DeleteModal";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Loader from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Services() {
  //=================================================================================================
  //   Constants Initialization
  //=================================================================================================
  const emptyService = {
    id: null,
    category: "EVOLVE CX",
    name: "",
    imageUrl: "",
    url: null,
    description: "",
    state: "Available",
  };
  const emptyCategory = {
    id: null,
    name: "",
    description: "",
  };

  //===============================================================================================
  //   States
  //===============================================================================================
  const [services, setServices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [serviceShow, setServiceShow] = useState(false);
  const [categoryShow, setCategoryShow] = useState(false);
  const [selectedService, setSelectedService] = useState(emptyService);
  const [selectedCategory, setSelectedCategory] = useState(emptyCategory);
  const [title, setTitle] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [image, setImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState("");
  const [email, setEmail] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [deleteServiceShow, setDeleteServiceShow] = useState(false);
  const [deleteCategoryShow, setDeleteCategoryShow] = useState(false);

  useEffect(() => {
    axios
      .get("/api/v1/DefaultProducts/All")
      .then((response) => {
        setServices(response.data.products);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }, []);

  useEffect(() => {
    axios
      .get("/api/v1/ProductCategories/All")
      .then((response) => {
        setCategories(response.data.categories);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }, []);

  useEffect(() => {
    axios
      .get("/api/v1/Config/ContactEmail")
      .then((response) => {
        setEmail(response.data.contactEmail);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }, [email]);

  //============================================================================================
  //  Functions
  //============================================================================================
  const handleServiceClose = () => setServiceShow(false);
  const handleCategoryClose = () => setCategoryShow(false);
  const handleAddServiceShow = () => {
    setServiceShow(true);
    setSelectedService(emptyService);
    setSelectedImage("");
    setImage(null);
    setTitle("Add a Service");
    setButtonText("Add");
  };
  const handleUpdateServiceShow = (row) => {
    setServiceShow(true);
    setSelectedService(row);
    setSelectedImage(row.imageUrl);
    setImage(null);
    setTitle("Update Service");
    setButtonText("Update");
  };

  const handleAddCategoryShow = (category) => {
    setCategoryShow(true);
    setSelectedCategory(emptyCategory);
    setTitle("Add Category");
    setButtonText("Add");
  };
  const handleUpdateCategoryShow = (category) => {
    setCategoryShow(true);
    setSelectedCategory(category);
    setTitle("Update Category");
    setButtonText("Update");
  };
  const handleLogoChange = (event) => {
    setSelectedImage(URL.createObjectURL(event.target.files[0]));
    setImage(event.target.files[0]);
    console.log(event.target.files[0])
  };

  function uploadImage() {
    let formData = new FormData();
    formData.append("file", image);
    let request = axios({
      method: "post",
      url: "/api/v1/Files/UploadImage",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
    return request;
  }

  function postService(formData, url) {
    let request = axios({
      method: "post",
      url: url,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        accept: "text/plain",
      },
      withCredentials: true,
    });
    return request;
  }

  function prepareServiceFormData(
    id,
    name,
    categoryId,
    imageUrl,
    description,
    state
  ) {
    let formData = new FormData();
    if (id !== null) {
      formData.append("id", id);
    }
    formData.append("name", name);
    formData.append("categoryId", categoryId);
    formData.append("imageUrl", imageUrl);
    formData.append("description", description);
    formData.append("defaultState", state);
    return formData;
  }

  function updateServices(id, name, categoryId, imageUrl, description, state) {
    let items = services;
    const index = services.findIndex(
      (element) => element.id == selectedService.id
    );
    if (index >= 0) {
      items[index] = {
        id: selectedService.id,
        name: name,
        categoryId: categoryId,
        imageUrl: imageUrl,
        description: description,
        state: state,
      };
    } else {
      let item = {
        id: id,
        name: name,
        categoryId: categoryId,
        imageUrl: imageUrl,
        description: description,
        state: state,
      };
      items.push(item);
    }
    setServices(items);
  }
  function updateCategories(id, name, description) {
    let items = categories;
    const index = categories.findIndex(
      (element) => element.id == selectedCategory.id
    );
    if (index >= 0) {
      items[index] = {
        id: selectedCategory.id,
        name: name,
        description: description,
      };
    } else {
      let item = {
        id: id,
        name: name,
        description: description,
      };
      items.push(item);
    }
    setCategories(items);
  }
  const handleServiceSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const i = categories.findIndex(
      (element) => element.name == event.target.category.value
    );
    const name = event.target.name.value;
    const description = event.target.description.value;
    const category = event.target.category.value;
    const categoryId = categories[i].id;
    if (image !== null) {
      uploadImage().then((response) => {
       
        const i = categories.findIndex((element) => element.name == category);
        var serviceFormData = prepareServiceFormData(
          selectedService.id,
          name,
          categoryId,
          response.data.url,
          description,
          selectedService.state
        );
        var url = "/api/v1/DefaultProducts/Update";
        if (selectedService.id === null) {
          url = "/api/v1/DefaultProducts/New";
        }
        
        postService(serviceFormData, url)
          .catch(function (e) {
            console.log(e);
          })
          .then((response) => {
            updateServices(
              response.data.id,
              name,
              categoryId,
              response.data.url,
              description,
              selectedService.state
            );
            
            setIsLoading(false);
            setServiceShow(false);
          });
      });
    } else {
      const i = categories.findIndex((element) => element.name == category);
      var serviceFormData = prepareServiceFormData(
        selectedService.id,
        name,
        categoryId,
        selectedImage,
        description,
        selectedService.state
      );
      var url = "/api/v1/DefaultProducts/Update";
      if (selectedService.id === null) {
        url = "/api/v1/DefaultProducts/New";
      }
      console.log("url:", url);
      postService(serviceFormData, url)
        .catch(function (e) {
          console.log(e);
        })
        .then((response) => {
          updateServices(
            response.data.id,
            name,
            categoryId,
            selectedImage,
            description,
            selectedService.state
          );
          // console.log(serviceFormData["name"]);
          console.log(response.data);
          setIsLoading(false);
          setServiceShow(false);
        });
    }
  };

  const handleCategorySubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    var description = event.target.description.value;
    var name = event.target.name.value;
    console.log("description:", description);
    var categoryFormData = new FormData();
    categoryFormData.append("id", selectedCategory.id);
    categoryFormData.append("name", name);
    categoryFormData.append("description", description);
    var url = "/api/v1/ProductCategories/Update";
    if (selectedCategory.id === null) {
      url = "/api/v1/ProductCategories/New";
    }
    axios({
      method: "post",
      url: url,
      data: categoryFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        accept: "text/plain",
      },
      withCredentials: true,
    }).then((response) => {
      updateCategories(response.data.id, name, description);
      setIsLoading(false);
      setCategoryShow(false);
    });
  };

  const handleEmailSubmit = (event) => {
    event.preventDefault();
    var newEmail = event.target.email.value;
    console.log("email:", newEmail);
    if (email != newEmail) {
      setIsLoading(true);
      var formData = new FormData();
      formData.append("contactEmail", newEmail);
      axios({
        method: "post",
        url: "/api/v1/Config/ContactEmail",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          accept: "text/plain",
        },
        withCredentials: true,
      }).then((response) => {
        setEmail(newEmail);
        setIsLoading(false);
        
      });
    }
  };

  //
  const handleDeleteServiceShow = (row) => {
    setSelectedService(row);
    setDeleteServiceShow(true);
  };
  const handleDeleteCategoryShow = (row) => {
    setSelectedCategory(row);
    setDeleteCategoryShow(true);
  };
  const handleDeleteServiceClose = () => {
    setDeleteServiceShow(false);
  };
  const handleDeleteCategoryClose = () => {
    setDeleteCategoryShow(false);
  };
  const handleDeleteService = () => {
    let items = services;
    const index = services.findIndex(
      (element) => element.id == selectedService.id
    );
    setIsLoading(true);
    axios
      .delete("/api/v1/DefaultProducts/Delete/" + selectedService.id, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status=='200') {
          console.log("response success",response.success)
          items.splice(index, 1);
          setServices(items);
          setDeleteServiceShow(false);
          setIsLoading(false);
          toast.success("Successfully deleted!");
        } else {
          setDeleteServiceShow(false);
          setIsLoading(false);
          toast.error("Something went wrong")
        }
      }).catch(function (error) {
        console.log(error.response);
        setDeleteServiceShow(false);
        setIsLoading(false);
        toast.error("Something went wrong")
      });
  };

  const handleDeleteCategory = () => {
    let items = categories;
    const index = categories.findIndex(
      (element) => element.id == selectedCategory.id
    );
    setIsLoading(true);
    axios
      .delete("/api/v1/ProductCategories/Delete/" + selectedCategory.id, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status=='200') {
          items.splice(index, 1);
        setCategories(items);
        setDeleteCategoryShow(false);
        setIsLoading(false);
        toast.success("Successfully deleted")
        }else{
          toast.error("Something went wrong")
        }
        
      }).catch(function (error) {
        console.log(error.response);
        setDeleteCategoryShow(false);
        setIsLoading(false);
        toast.error("Something went wrong")
      });
  };
  //================================================================================================
  //   Render
  //================================================================================================
  return (
    <>
      {isLoading ? (
        <Loader
          className="nothing-display"
          type="Oval"
          color="#0F204B"
          height={100}
          width={100}
        />
      ) : (
        <div className="container-fluid max-width">
          <Col className="pb-5">
              {/* <ToastContainer position="top-right" />
              {/* Same as */}
              <ToastContainer /> 
                <div className="my-4">
                  <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="large" />}
                    aria-label="breadcrumb"
                  >
                    <Link to="/admin">Admin</Link>
                    <Typography className="MuiBreadcrumbs-li-last">
                      Manage Services
                    </Typography>
                  </Breadcrumbs>
                </div>
                <Row>
                  <Col className="col-md-6 col-lg-4">
                      <h5 className="text-left">Services</h5>
                      <Button
                        className="btn btn-primary my-2"
                        onClick={() => handleAddServiceShow()}
                      >
                        <i className="fas fa-plus"></i>&nbsp; Add New Service
                      </Button>
                      <ServiceTable
                        data={services}
                        handleShow={handleUpdateServiceShow}
                        handleDeleteShow={handleDeleteServiceShow}
                      />
                  </Col>
                  <Col className="col-md-6 col-lg-4">
                      <h5 className="text-left">Categories</h5>
                      <Button
                        className="btn btn-primary my-2"
                        onClick={() => handleAddCategoryShow()}
                      >
                        <i className="fas fa-plus"></i>&nbsp; Add New Category
                      </Button>
                      <ServiceTable
                        data={categories}
                        handleShow={handleUpdateCategoryShow}
                        handleDeleteShow={handleDeleteCategoryShow}
                      />
                  </Col>
                  <Col className="col-md-6 col-lg-4">
                      <Form id="email-form" onSubmit={handleEmailSubmit}>
                        <Form.Group as={Col} controlId="email">
                          <Form.Label>Contact email address</Form.Label>
                          <Form.Control
                            type="email"
                            defaultValue={email}
                          ></Form.Control>
                          <Button form="email-form" variant="primary" type="submit" className="float-right mt-3">
                            Update
                          </Button>
                        </Form.Group>  
                      </Form>
                  </Col>
                </Row>
                <ServiceModal
                  show={serviceShow}
                  title={title}
                  buttonText={buttonText}
                  handleClose={handleServiceClose}
                  categories={categories}
                  handleSubmit={handleServiceSubmit}
                  service={selectedService}
                  selectedImage={selectedImage}
                  handleLogoChange={handleLogoChange}
                />
                <CategoryModal
                  show={categoryShow}
                  title={title}
                  buttonText={buttonText}
                  handleClose={handleCategoryClose}
                  handleSubmit={handleCategorySubmit}
                  category={selectedCategory}
                />
                <DeleteModal
                  show={deleteServiceShow}
                  handleDelete={handleDeleteService}
                  handleClose={handleDeleteServiceClose}
                  type="service"
                />
                <DeleteModal
                  show={deleteCategoryShow}
                  handleDelete={handleDeleteCategory}
                  handleClose={handleDeleteCategoryClose}
                  type="category"
                />
          </Col>
        </div>
      )}
    </>
  );
}

export default Services;
