import React, {useState} from "react";
import { Container, Row, Col, Modal, Form, Button } from "react-bootstrap";

function CategoryModal(props) {
  //==============================================================================================================
  //
  //==============================================================================================================
  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="category-form" onSubmit={props.handleSubmit}>
        <Row>
            <Form.Group as={Col} controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                as="input"
                defaultValue={props.category.name}
                required
              ></Form.Control>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="description">
              <Form.Label>Descriptive Text</Form.Label>
              <Form.Control
                as="textarea"
                defaultValue={props.category.description}
                required
              ></Form.Control>
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Cancel
        </Button>
        <Button variant="primary" type="submit" form="category-form">
          {props.buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CategoryModal;
