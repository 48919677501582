import React, { useState, useEffect } from "react";
import axios from "axios";
import DocumentStorageTable from "../../Components/Tables/DocumentStorageTable";
import DocumentModal from "../../Components/Modals/DocumentStorageModal";
import DeleteModal from "../../Components/Modals/DeleteModal";
import Select from "react-select";
//import { Link } from "react-router-dom";
//import AccountCircleIcon from "@material-ui/icons/AccountCircle";
//import BusinessIcon from "@material-ui/icons/Business";
//import SettingsIcon from "@material-ui/icons/Settings";
//import PostAddIcon from '@material-ui/icons/PostAdd';
//import EditIcon from '@material-ui/icons/Edit';
//import { Container, Row, Col, Card } from "react-bootstrap";
import "./InternalDocumentStorage.css";
import { ToastContainer, toast } from "react-toastify";


//
function InternalDocumentStorage() {

    const emptyDocument = {
        id: null,
        category: "EVOLVE CX",
        Title: "",
        documentlocation: "",
        url: null,
        description: "",
        owner: "",
        locationupdateddate: "",
        filename: "",
        downloadlink:""
    };

    // eslint-disable-next-line no-unused-vars
    const [documentShow, setDocumentShow] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [documents, setDocuments] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedDocCategory, setSelectedDocCategory] = useState("");
    const [selectedDocument, setSelectedDocument] = useState(emptyDocument);
    const [title, setTitle] = useState("");
    const [buttonText, setButtonText] = useState("");
    //const [selectedFile, setSelectedFile] = useState("");
    const [file, setfile] = useState(null);
    const [isAdd, setIsAdd] = useState(false);
    const [deleteDocumentShow, setDeleteDocumentShow] = useState(false);
    const [selectedClientValue, setSelectedClientValue] = useState();
    const [clients, setClients] = useState([]);
    const [isAddButtonEnabled, setIsAddButtonEnabled] = useState(false);
   
    useEffect(() => {
        axios
            .get("/api/v1/ProductCategories/All")
            .then((response) => {
                setCategories(response.data.categories);
            })
            .catch(function (error) {
                console.log(error.response);
            });

        axios
            .get("/api/v1/Products/My")
            .then((response) => {
            
                var i;
                var clients = [];
                for (i = 0; i < response.data.clients.length; i++) {
                    if (response.data.clients[i].categories.includes("Internal Applications")) {
                        clients.push(response.data.clients[i]);
                    }
                }
               setClients(clients);
            })
            .catch(function (error) {
                console.log(error.response);
            });
    }, []);

    const handleDocumentClose = () => setDocumentShow(false);

    const handleAddDocumentShow = () => {
        setDocumentShow(true);
        setSelectedDocument(emptyDocument);
        setTitle("Add Document");
        setButtonText("Add");
        setIsAdd(true);
    };

    const handleUpdateDocumentShow = (document) => {
      
        const i = categories.findIndex(
            (element) => element.id == document.categoryId
        );
     
        setSelectedDocCategory(categories[i].name);

        setDocumentShow(true);
        setSelectedDocument(document);
        setTitle("Update Document");
        setButtonText("Update");
        setIsAdd(false);
    };

    const handleDeleteDocumentShow = (document) => {
        setSelectedDocument(document);
        setDeleteDocumentShow(true);
    };
    const handleDeleteDocumentClose = () => {
        setDeleteDocumentShow(false);
    };

    const handleSelectClientChange = (event) => {
        let theValue = event.value;
        setSelectedClientValue(theValue);
        handleSelectedValue(theValue);
        setIsAddButtonEnabled(theValue !== '');
    }

    const handleSelectedValue = (theValue) => {
        axios.get(`/api/v1/InternalDocument/All/?selectedId=${theValue}`).then((response) => {
            setDocuments(response.data.documents); 
         
       })
           .catch(function (error) {
               console.log(error.response);
           });;
    };
   
    function uploadImage() {
       
        let formData = new FormData();
        formData.append("file", file);
       
        let request = axios({
            method: "post",
            url: "/api/v1/Files/UploadDocumentStorageFile",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        });
        return request;
    }

   
    const handleFileChange = (event) => {
      
        setfile(event.target.files[0]);
    };

    const handleDeleteDocument = () => {
      
        let items = documents;
        const index = documents.findIndex(
            (element) => element.id == selectedDocument.id
        );
        setIsLoading(true);
        axios
            .delete("/api/v1/InternalDocument/Delete/" + selectedDocument.id, {
                withCredentials: true,
            })
            .then((response) => {
                if (response.status == '200') {
                    console.log("response success", response.success)
                    items.splice(index, 1);
                    setDocuments(items);
                    setDeleteDocumentShow(false);
                    setIsLoading(false);
                    toast.success("Successfully deleted!");
                } else {
                    setDeleteDocumentShow(false);
                    setIsLoading(false);
                    toast.error("Something went wrong")
                }
            }).catch(function (error) {
                console.log(error.response);
                setDeleteDocumentShow(false);
                setIsLoading(false);
                toast.error("Something went wrong")
            });

        setDocuments(items);

    };

    const handleServiceSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);
        const i = categories.findIndex(
            (element) => element.name == event.target.category.value
        );

        const title = event.target.title.value;
        const description = event.target.description.value;
        const categoryId = categories[i].id;
        const category = categories[i].value;
        const location = event.target.location.value;
        const currentDate = new Date();
        const month = currentDate.getMonth() + 1;
        const year = currentDate.getFullYear();
        const date = currentDate.getDate();
        const modifiedDate = month + "/" + date + "/" + year;
        const downloadLink = "" + event.target.filename.value;

        setSelectedDocCategory(category);

        if (file !== null) {
          
            uploadImage().then((response) => {
              
                var serviceFormData = prepareDocumentFormData(
                    selectedDocument.id,
                    title,
                    categoryId,
                    description,
                    location,
                    response.data.url,
                    modifiedDate,
                    response.data.fileSize,
                    response.data.filename,
                    selectedClientValue
                    
                );
                var url = "/api/v1/InternalDocument/Update";
                if (selectedDocument.id === null) {
                    url = "/api/v1/InternalDocument/New";
                }
               
                postService(serviceFormData, url)
                    .then((response) => {
                        setDocuments(response.data.documents);
                       
                        setSelectedDocument(response.data);
                      
                        updateDocuments(
                            selectedDocument.id,
                            title,
                            categoryId,
                            file,
                            description,
                            location,
                            modifiedDate,
                            response.data.size,
                            response.data.fileName,
                            selectedClientValue,
                            downloadLink
                        );
                        
                        setIsLoading(false);
                        setDocumentShow(false);
                    });
            });
        } else {
           
            var serviceFormData = prepareDocumentFormData(
                selectedDocument.id,
                title,
                categoryId,
                description,
                location,
                file,
                modifiedDate,
                selectedDocument.size,
                selectedDocument.fileName,
                selectedClientValue

        );
        var url = "/api/v1/InternalDocument/Update";
        if (selectedDocument.id === null) {
            url = "/api/v1/InternalDocument/New";
        }
      
        postService(serviceFormData, url)
            .then((response) => {
                setSelectedDocument(response.data);
                
                updateDocuments(
                    response.data.id,
                    title,
                    categoryId,
                    file,
                    description,
                    location,
                    modifiedDate,
                    response.data.size,
                    response.data.fileName,
                    selectedClientValue,
                    downloadLink
                    
                );
               
                setIsLoading(false);
                setDocumentShow(false);
               
            });
    }
    };

    function postService(formData, url) {
     
        let request = axios({
            method: "post",
            url: url,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                accept: "text/plain",
            },
            withCredentials: true,
        });
        return request;
    }

    function prepareDocumentFormData(
        id,
        title,
        categoryId,
        description,
        location,
        csvFile,
        modifieddate,
        filesize,
        filename,
        clientId,
        owner
       
    ) {
        let formData = new FormData();
        if (id !== null) {
            formData.append("id", id);
        }
        formData.append("title", title);
        formData.append("categoryId", categoryId);
        formData.append("Documentlocation", location);
        formData.append("description", description);
        formData.append("DocumentLink", csvFile);
        formData.append("modifiedDate", modifieddate);
        formData.append("Size", filesize);
        formData.append("FileName", filename);
        formData.append("ClientId", clientId);
        //formData.append("owner", owner);
        return formData;
    }

    function updateDocuments(id, title, categoryId, fileurl, description, locationoffile, modifieddate, filesize, filename, clientid, downloadLink) {
       
        let items = documents;
        const index = documents.findIndex(
            (element) => element.id == selectedDocument.id
        );
       
        if (index >= 0) {
            items[index] = {
                id: selectedDocument.id,
                title: title,
                description: description,
                category: categoryId,
                documentlink: fileurl,
                documentLocation: locationoffile,
                modifiedDate: modifieddate,
                size: filesize,
                fileName: filename,
                clientid: clientid,
                locationupdatedDate: modifieddate,
                owner:selectedDocument.owner,
                downloadLink:downloadLink
            };
        } else {
            let item = {
                id:id,
                title: title,
                description: description,
                category: categoryId,
                documentlink: fileurl,
                documentLocation: locationoffile,
                modifiedDate: modifieddate,
                size: filesize,
                fileName: filename,
                clientid: clientid,
                locationupdatedDate: modifieddate,
                owner: "",
                downloadLink: downloadLink
            };
            items.push(item);
            setSelectedDocument(item);
        }
     
        setDocuments(items);
        handleSelectedValue();
    }
   
    return (
        <>
            <ToastContainer /> 
            <div className="container pb-5">
                <h2 className="my-5 text-center">Internal Document Storage</h2>
                <div style={{lineHeight: "50px"}}>
                    <label style={{paddingRight: "20px", display: "inline-block"}} htmlFor="dropdown">Select Client:</label>
                    <div style={{ display: "inline-Block", width: "400px", lineHeight: "20px" }}>
                        <Select 
                            className="dropdown" 
                            placeholder="Select Option" 
                            options={clients.map((option) => (
                                {"value": option.id, "label": option.name}))}
                            singleValue={selectedClientValue}
                            onChange={handleSelectClientChange} />
                    </div>
                    <button disabled={!isAddButtonEnabled}
                        className="btn btn-primary my-2"
                        style={{float: "right"}}
                        onClick={() => handleAddDocumentShow()}
                    >
                        <i className="fas fa-plus"></i>&nbsp; Add New Document
                    </button>
                </div>
                <DocumentStorageTable
                    data={documents}
                    categories={categories}
                    handleShow={handleUpdateDocumentShow}
                    handleDeleteShow={handleDeleteDocumentShow}
                />
                <DocumentModal //popup
                    show={documentShow}
                    title={title}
                    handleSubmit={handleServiceSubmit}
                    handleClose={handleDocumentClose}
                    buttonText={buttonText}
                    selectedFile={file}
                    handleFileChange={handleFileChange}
                    categories={categories}
                    isAdd={isAdd}
                    document={selectedDocument}
                    selectedDocCategory={selectedDocCategory}
                />
                <DeleteModal
                    show={deleteDocumentShow}
                    handleDelete={handleDeleteDocument}
                    handleClose={handleDeleteDocumentClose}
                    document={selectedDocument }
                />
            </div>
           
        </>
    );
}


export default InternalDocumentStorage;
