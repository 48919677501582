import React, {useState} from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { toast } from "react-toastify";
import { useEffect } from "react";

function ServiceUpdateForm(props) {
  const [service, setService] = useState({
    id: props.service.id,
    name:props.service.name,
    url:props.service.url,
    imageUrl: props.service.imageUrl,
    veracityServiceId: props.service.veracityServiceId
  });
  const [serviceImage, setServiceImage] = useState(null);
  const [selectedServiceImage, setSelectedServiceImage] = useState(
    props.service.imageUrl
  );
  const [isLoading, setIsLoading] = useState(false);
  const [entityId, setEntityId] = useState(props.service.vapEntityId);
  const [entityUrl, setEntityUrl] = useState(props.service.url);
  //const [entityDisplayName, setEntityDisplayName] = useState(props.service.vAPEntity ? props.service.vapEntity.entityDisplayName: "");
  const [isVAPService, setIsVAPService] = useState(props.service.category === "EVOLVE Intelligence");

  useEffect(() => {
    setSelectedServiceImage(props.service.imageUrl);
  }, [props])

  const handleServiceImageChange = (event) => {
    setSelectedServiceImage(URL.createObjectURL(event.target.files[0]));
    setServiceImage(event.target.files[0]);
  };
  function uploadImage() {
    let formData = new FormData();
    formData.append("file", serviceImage);
    let request = axios({
      method: "post",
      url: "/api/v1/Files/UploadImage",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
    return request;
  }
  const handleEntityChange = (event) =>{
    let entityArr = props.availableOptions.filter(e => e.value == event.target.value);
    let entityUrl = entityArr && entityArr.length > 0 && entityArr[0].url;
    //let entityDisplayName = entityArr && entityArr.length > 0 && entityArr[0].text;
    setService({
      ...service,
      url: entityUrl
    });
    setEntityUrl(entityUrl);
    //setEntityDisplayName(entityDisplayName);
  }

    function handleFormSubmit(event) {
    var name =event.target.name.value;
    var url = isVAPService ? entityUrl : event.target.url.value;
    var entityId = isVAPService ? event.target.entityId.value: null;
    if(isVAPService && !entityId){
      toast.error("Entity should not be empty!");
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    var veracityServiceId=event.target.veracityServiceId.value;
    console.log("id to submit:",props.service.id);
    setIsLoading(true);
    if (serviceImage !== null) {
      console.log("image: yes")
      uploadImage().then((response) => {
        var formData = new FormData();
        formData.append("clientProductId", props.service.id);
        formData.append("clientId", props.clientId);
        formData.append("name", name);
        formData.append("veracityServiceId", veracityServiceId);
        formData.append("url", url);
        var imageUrl= response.data.url;
        formData.append("imageUrl", response.data.url);
        entityId && formData.append("vAPEntityId",entityId);
        //entityDisplayName && formData.append("entityDisplayName", entityDisplayName);
        axios({
          method: "post",
          url: "/api/v1/Products/Update/Subscribed",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            accept: "text/plain",
          },
          withCredentials: true,
        }).then(response => {
          setService({
            id:props.service.id,
            name:name,
            url:url,
            imageUrl:imageUrl,
            veracityServiceId:veracityServiceId,
          })
          setEntityId(entityId);
          setIsLoading(false)
        });
      });
    }else{
      var formData = new FormData();
      console.log("image: NO");
      formData.append("clientProductId", props.service.id);
      formData.append("clientId", props.clientId);
        formData.append("name", name);
        formData.append("url", url);
        formData.append("veracityServiceId", veracityServiceId);
        formData.append("imageUrl",props.service.imageUrl);
        entityId && formData.append("vAPEntityId",entityId);
        //entityDisplayName && formData.append("entityDisplayName", entityDisplayName);
        axios({
          method: "post",
          url: "/api/v1/Products/Update/Subscribed",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            accept: "text/plain",
          },
          withCredentials: true,
        }).then(response => {
          let data = response.data;
          setService({
            id:props.service.id,
            name:name,
            url:data.url,
            veracityServiceId:veracityServiceId,
            imageUrl:props.service.url
          })
          setEntityId(entityId);
          setIsLoading(false);
        }).catch(ex => {
          setEntityId(entityId);
          setIsLoading(false);
        });
    }
  }
  return (
    <>
    {isLoading? 
      <Loader className="nothing-display"
      type="Oval"
      color="#0F204B"
      height={100}
      width={100}
  />:
    <Form
      id="update-service-form"
      onSubmit={(event) => handleFormSubmit(event)}
    >
      <Form.Group as={Col} controlId="name">
        <Form.Label>Service Name</Form.Label>
        <Form.Control as="input" defaultValue={service.name} />
      </Form.Group>
      <Form.Group as={Col} controlId="veracityServiceId">
        <Form.Label>Veracity Id</Form.Label>
        <Form.Control as="input" defaultValue={service.veracityServiceId} />
      </Form.Group>
      {
        !isVAPService && (
          <Form.Group  as={Col} controlId="url">
            <Form.Label>Service URL</Form.Label>
            <Form.Control as="input"  defaultValue={service.url} />
          </Form.Group>
        )
      }      
      {
        isVAPService && (
          <Form.Group as={Col} controlId="entityId">
            <Form.Label>VAP Entity</Form.Label>
            <Form.Control as="select" key={props.availableOptions.length} defaultValue={entityId} onChange={handleEntityChange.bind(this)}>
              <option value=""></option>
              {
                props.availableOptions.length>0 ? props.availableOptions.map((option) => (
                  <option key={option.value}  value={option.value}>{option.text}</option>
                ))  
                :<option value="" disabled>No Options here</option>
              }
            </Form.Control>
          </Form.Group>
        )
      }
      
      {/* {console.log("selectedImage", selectedImage)} */}

      {selectedServiceImage === "" ? null : (
        <Col>
        <img
          src={selectedServiceImage}
          alt="image selected"
          className="img-thumbnail"
        />
        </Col>
      )}
      <Form.Group as={Col} controlId="imageUrl">
        <Form.File
          label="Upload Image"
          onChange={(event) => handleServiceImageChange(event)}
        />
      </Form.Group>
      <Col>
        <Button variant="primary" type="submit">
          Update Service
        </Button>
      </Col>
    </Form>}
    </>
  );
}

export default ServiceUpdateForm;
