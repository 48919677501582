import React from 'react';
import './Footer.css';

const Footer = () => (
    <footer role="contentinfo" className="footer small mt-auto">
        <div className="footer-small container-fluid m-0 p-0">
            <ul className="footer-small-links">
                <li>
                    <a
                        target="_self"
                        href="mailto:support.evolve@dnv.com?subject=Evolve Hub Support
                                &body=I require assistance with Evolve Hub. Please describe the issue: "
                        className=""
                    >
                        Contact support
                    </a>
                </li>
                <li>
                    {/* <a
                        target="_self"
                        href="https://www.dnvgl.com/software/video-webinar/index.html"
                        className=""
                    >
                        Videos and webinars
                    </a> */}
                </li>
            </ul>
            <div className="footer-small-dnvgl">
                <div className="my-3 mr-3 my-sm-0 mr-sm-0 title-spacing">
                    © DNV {(new Date()).getFullYear()}
                </div>
                <span className="mx-3 d-none d-sm-inline">
                    |
                </span>
                <ul className="footer-some">
                    <li className="mr-2">
                        <a
                            target="_blank"
                            href="https://www.linkedin.com/showcase/dnv-energysystems/"
                            className="some-circle small"
                        >
                            <i className="fab fa-linkedin-in"></i>
                        </a>
                    </li>
                    <li className="mr-2">
                        <a
                            target="_blank"
                            href="https://twitter.com/DNVEnergySystem"
                            className="some-circle small"
                        >
                            <i className="fab fa-twitter"></i>
                        </a>
                    </li>
                    {/* <li className="mr-2">
                        <a
                            target="_self"
                            href="https://www.facebook.com/dnvglsoftware/"
                            className="some-circle small"
                        >
                            <i className="fab fa-facebook"></i>
                        </a>
                    </li> */}
                    {/* <li>
                        <a
                            target="_self"
                            href="https://www.youtube.com/user/dnvsoftware"
                            className="some-circle small"
                        >
                            <i className="fab fa-youtube"></i>
                        </a>
                    </li> */}
                </ul>
            </div>
        </div>
    </footer>
)

export default Footer;