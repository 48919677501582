import React, { useState } from "react";
import {Modal, Button } from "react-bootstrap";

function DeleteApprovedUserModal(props) {
  return (
    <Modal size="lg" show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete User</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={props.handleDelete}>Delete User</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteApprovedUserModal;
