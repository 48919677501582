import React from "react";
import AuthenticatedApp from "./Components/AuthenticatedApp";
import UnauthenticatedApp from "./Components/UnauthenticatedApp";
import { AuthConsumer } from "./Context/AuthContext";
import { AdminProvider } from "./Context/AdminContext";
import "./App.css";

function App() {
  return (
    <AuthConsumer>
      {({ isLoading, isSignedIn }) =>
        isLoading ? (
          <div></div>
        ) : isSignedIn ? (
                      <>
            <AdminProvider>
              <AuthenticatedApp />
            </AdminProvider>
          </>
        ) : (
          <UnauthenticatedApp />
        )
      }
    </AuthConsumer>
  );
}

export default App;
