import React, { useState, useEffect } from "react";
import "./ServiceModal.css";
import { Container, Row, Col, Modal, Form, Button } from "react-bootstrap";

function ServiceModal(props) {
  //=====================================================================================================
  // Functions
  //=====================================================================================================
  
  return (
    <Modal size="lg" show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="service-form" onSubmit={props.handleSubmit}>
          <Row>
            <Form.Group as={Col} controlId="category">
              <Form.Label>Product Category</Form.Label>
              <Form.Control as="select" defaultValue={props.service.category}>
                {props.categories.length>0 ?
                
                  props.categories.map((category) => (
                  <option key={category.id}>{category.name}</option>
                ))
                :<option disabled>No Options here</option>
                }
                
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} controlId="name">
              <Form.Label>Service Name</Form.Label>
              <Form.Control
                as="input"
                defaultValue={props.service.name}
                required
              ></Form.Control>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="image">
              {props.selectedImage === "" ? 
              <Form.File
                label="Upload Image"
                onChange={(event) => props.handleLogoChange(event)}
                required
              /> : (
                <>
                <img
                  src={props.selectedImage}
                  alt="image selected"
                  className="img-thumbnail"
                />
                <Form.File
                label="Upload Image"
                onChange={(event) => props.handleLogoChange(event)}
              />
                </>
              )}
              
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="description">
              <Form.Label>Descriptive Text</Form.Label>
              <Form.Control
                as="textarea"
                defaultValue={props.service.description}
              ></Form.Control>
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Cancel
        </Button>
        <Button variant="primary" type="submit" form="service-form">
          {props.buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ServiceModal;
