import React, { useState, useEffect } from "react";
import Chip from "@material-ui/core/Chip";
import Select from "react-select";
import { Container, Row, Col, Modal, Form, Button } from "react-bootstrap";
import { Subscriptions } from "@material-ui/icons";

function AvailableServiceModal(props) {
  return (
    <Modal size="lg" show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.service.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
            {props.service.description}
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={props.handleClose}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AvailableServiceModal;
