import React from 'react'

const MySortedTh = (props: mySortedTh) => {
    let theStyle : React.CSSProperties = {cursor: 'pointer'}
    return (<th onClick={props.onThClick} style={ props.Style || theStyle } className={props.ClassName ? props.ClassName : ''}>
    <span>{props.Title}</span>
    {props.SortAble === true && <span style={{marginLeft: '0.5rem'}} className='sortIcons'>
        <span style={{position: 'absolute'}} className={((props.CurrentSortName === props.SortColumnName) && (props.SortType === SortType.DESC)) ? 'sortUpDisabled' : 'sortUp'} 
            role="img">
                <i className="fas fa-sort-up"></i></span>
        <span className={((props.CurrentSortName === props.SortColumnName) && (props.SortType === SortType.ASC)) ? 'sortDownDisabled' : 'sortDown'} 
            role="img">
                <i className="fas fa-sort-down"></i></span>
    </span>}
    { props.children }
</th>)
}
export default MySortedTh

export interface mySortedTh{
    Title: string, 
    onThClick?: (e: React.MouseEvent | React.KeyboardEvent) => void, 
    SortAble: boolean,
    ClassName?: string,
    SortColumnName: string,
    CurrentSortName: string,
    SortType?: string,
    children?: React.ReactNode,
    Style?: React.CSSProperties
}

export enum SortType{
    ASC = 'ASC',
    DESC = 'DESC'
}

export const onThClickHelper = (sortColumnName: string, sortType: string, theSortColumnName: string, setSortColumnName: Function, setSortType: Function) => {
        if (!sortColumnName) {
            setSortColumnName(theSortColumnName)
            setSortType(SortType.ASC)
            return
        }
    
        if (theSortColumnName === sortColumnName) {
            switch (sortType) {
                case SortType.DESC:
                    setSortType('')
                    setSortColumnName('')
                    break
                case SortType.ASC:
                    setSortType(SortType.DESC)
                    break
                default:
                    setSortType(SortType.ASC)
                    break
            }
        }
        else {
            setSortColumnName(theSortColumnName)
            setSortType(SortType.ASC)
        }
    }
