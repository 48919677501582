import React from "react";
import "./Landing.css";

function Landing() {
  const searchUri = `${window.location.pathname}${window.location.search}`;
  return (
    <main role="main" className="h-100 landing">
      <div className="bgimage">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-10 mx-auto">
              <div className="login-text">
                <h1>EVOLVE<span>TM</span></h1>
                <p className="pt-3 pb-4">The complete suite of digital energy program solutions to shape your future</p>
                <a href={searchUri ? `/account/SignIn?ReplyUrl=${searchUri}` : '/account/SignIn'} type="button" className="btn btn-primary w-100 px-3">Log in</a>
              </div>
            </div>
            <div className="evolve-graphic"></div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Landing;
