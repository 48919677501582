import React from "react";
import "./CardList.css";

function Card(props) {
  return (
    <a target="_blank" href={props.service.url} className="card card-small services flex-row">
      <div className="img-section">
        <img src={props.service.imageUrl} className="card-img" alt={props.service.name} />
      </div>
      <div className="card-body">
        {props.service.name}
      </div>
    </a>
    
  );
}

export default Card;
