import React, {useState, useEffect} from "react";
import { PageProperties } from "./../../Model/Model"

const MyPagination = (props: PageProperties) => {
    const [pageSize, setPageSize] = useState<number>(10)
    const getPages = () =>{
        let pgs: number[] = []
        if(props.totalCount && props.totalCount > 0)
        {
            let totalCount: number = props.totalCount
            let pageCount = totalCount / pageSize + (totalCount%pageSize === 0 ? 0 : 1)
            for(let i = 1; i<= pageCount; i++)
            {
                pgs.push(i)
            }
            return pgs
        }
        else
        {
            return []
        }
    }

const onPageSizeChange = (e: any) =>{
    let size = e.target.value
    setPageSize(size)
    props.onPageSizeChange(size)
}

return (getPages() && 
    <div className="pagesDiv">
        <div className="pageSizeDiv">
            <select onChange={(e) => onPageSizeChange(e)}>
                <option>10</option>
                <option>20</option>
                <option>100</option>
            </select>
        </div>
        <div className="myPage">
            {props.currentPage > 1 && <li onClick={() => props.onClick(props.currentPage - 1)}>&lt;</li>}
            {(getPages()).map((p) => {
            return <li key={p} className={props.currentPage === p ? "current" : ""} 
            onClick={() => props.onClick(p)}>
                <span>{p.toString()}</span>
            </li>
        })}
        {props.currentPage < (getPages().length) && <li onClick={() => props.onClick(props.currentPage + 1)}>&gt;</li>}
        </div>
    </div>)
}

export default MyPagination