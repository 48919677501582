import React from 'react';
import Loader from 'react-loader-spinner';

function Display(props) {
    const isLoading = props.isLoading;

    if (isLoading) {
        return (
            <Loader className="nothing-display"
                type="Oval"
                color="#0F204B"
                height={100}
                width={100}
            />
        )
    }
    else
        return (
            <div className="display">
                {props.results.map(item => (
                    <p className="list-apicall" key={item.id}>{item.name}: ${item.price}</p>
                ))}
            </div>
        )
}

export default Display;