import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ApiCall.css';
import Display from '../../Components/Display';

function ApiCall() {
    //const apiVersion = React.useContext(ApiContext);
    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        //axios.get('/api/v1.0/SampleData/sampledata')
        let url = window.location.href;
        let apiUrl = url.indexOf('updateimageurl') > 0 ? '/api/v1/Client/UpdateImageUrl' : 
                    url.indexOf('updateusertype') > 0 ? '/api/v1/User/updateusertype':
                    '/api/v1/Client/updateblobversion'
        axios.get(apiUrl)
            .then(function (response) {
                setResults(response.data);
                setIsLoading(false);
            })
            .catch(function (error) {
                // handle error
                console.log(error.response);
            })
    }, []);

    if (results !== null) {
        if (Array.isArray(results)) {
            return (
                <div className="centralize-apicall">
                    <br /><h2>ApiCall</h2><br />
                    <Display results={results} isLoading={isLoading} />
                </div>
            )
        }
        else {
            return (
                <div className='centralize-apicall'>
                    <br />
                    {results}
                </div>
            )
        }
    }
    else return (
        <div className="centralize-apicall">
            <br />
            ApiCall - Nothing received.
        </div>
    )
}

export default ApiCall;