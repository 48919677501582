import React, { useState, useEffect } from "react"
import axios from "axios";
import "./UnapprovedHome.css";
import { User, ValidationError } from "../../Model/Model"

function UnapprovedHome() {
  const [contactEmail, setContactEmail] = useState("");
  const [pendingMe, setPendingMe] = useState<User>()
  const [activePendingMe, setActivePendingMe] = useState<User>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [validationError, setValidationError] = useState<ValidationError[]>()

  useEffect(() => {
    axios
      .get("/api/v1/Config/ContactEmail")
      .then((response) => setContactEmail(response.data.contactEmail))
      .catch(err => console.log(err.response))

    axios
      .get("/api/v1/Account/Info")
      .then((response) => setPendingMe(response.data))
      .catch(err => console.log(err.response))
  }, []);

  const onInputChange = (e: any) => {
    let ett : any = Object.assign({}, activePendingMe)
    if(!activePendingMe)
    {
      ett = Object.assign({}, pendingMe)
    }
    let value = e.target.value
    let name = e.target.name
    
    ett[name] = value
    setActivePendingMe(ett as User)
  }

  const validateData = () => {
    let errors : ValidationError[] = []

    if(!(activePendingMe?.desiredClient))
    {
      let newError: ValidationError = {id: "desiredClient", value: "This field cannot be empty."}
      errors.push(newError)
    }

    if(!(activePendingMe?.title))
    {
      let newError: ValidationError = {id: "title", value: "This field cannot be empty."}
      errors.push(newError)
    }

    setValidationError(errors)

    if(errors.length > 0)
    {
      return false
    }

    return true
  }

  const onSaveClick = () =>{
    if(!validateData())
      return

    let formData = new FormData();
        formData.append("id", activePendingMe?.id);
        formData.append("desiredClient", activePendingMe?.desiredClient as string);
        formData.append("title", activePendingMe?.title as string);

        setIsLoading(true)
        axios({
          method: "post",
          url: "/api/Account/Update",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
          let theData : User = data.data as User
          let oldEtt : User = Object.assign({}, pendingMe)
          oldEtt.desiredClient = theData.desiredClient
          oldEtt.title = theData.title
          setPendingMe(oldEtt)
          setActivePendingMe(undefined)
        }).catch(function (ex) {
          // handle error
        }).finally(() => {
          setIsLoading(false)
        })
  }
    return (
      <>
        <div className="centralize">
          <div className="container pb-5">
              <div className="unapproved-text">
                  Your access to the Evolve Hub has not been approved yet. Contact {contactEmail} to check your access status.
              </div>
              <div style={{paddingBottom: "20px"}}>
                  For a quick approval, please fill out the additional information below:
              </div>
              {pendingMe && <div>
                <div className="row">
                  <div className="form-group col">
                        <label>What Utility Partner would you like access for?</label>
                        {!pendingMe.desiredClient && <input value={activePendingMe?.desiredClient} onChange={(e) => onInputChange(e)} name="desiredClient" className="form-control"></input>}
                        {pendingMe.desiredClient && <div>{pendingMe.desiredClient}</div>}
                        {validationError?.find(x => x.id === "desiredClient") && <small style={{color: "red"}}>
                          {validationError?.find(x => x.id === "desiredClient")?.value}
                          </small>}
                    </div>
                    <div className="form-group col">
                        <label>What is your job role/title?</label>
                        {!pendingMe.title && <input value={activePendingMe?.title} onChange={(e) => onInputChange(e)} name="title" className="form-control"></input>}
                        {pendingMe.title && <div>{pendingMe.title}</div>}
                        {validationError?.find(x => x.id === "title") && <small style={{color: "red"}}>
                          {validationError?.find(x => x.id === "title")?.value}
                          </small>}
                    </div>
                </div>
                {(!pendingMe.title || !pendingMe.desiredClient) &&  
                  <div className="row">
                  <div className="form-group col" style={{textAlign: "right"}}>
                    <button disabled={isLoading} onClick={() => onSaveClick()} className="btn btn-primary">{isLoading ? "..." : "Save"}</button>
                  </div>
                </div>
                }
              </div>}
          </div>
        </div>
      </>
        
  )
}

export default UnapprovedHome;