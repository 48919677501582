import React, { useContext, useEffect, useState } from "react";
import "./ClientTabs.css";
import { Tab, Nav } from "react-bootstrap";
import TabContent from "../../Components/TabContent";
import { MyProductsContext } from "../../Context/MyProductsContext";

function ClientTabs(props) {
  const contextData = useContext(MyProductsContext);
  const data = contextData.myProducts;
  return Object.keys(data).length > 0 ? (
    <div className="tabs client">
      {data.clients.length > 0 ? (
        <Tab.Container defaultActiveKey={data.clients[0].name}>
          <Nav variant="tabs" as="ul">
            {data.clients.map((client, key) => (
              <Nav.Item as="li" key={key}>
                <Nav.Link
                  eventKey={client.name}
                  onSelect={(eventKey, event) =>
                    props.handleTabSelect(eventKey, event)
                  }
                >
                  {client.name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <Tab.Content>
            {data.clients.map((client, key) => (
              <Tab.Pane eventKey={client.name} key={key}>
                <img className="client-logo" src={client.logoUrl} />
                <TabContent client={client} />
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Tab.Container>
      ) : null}
    </div>
  ) : null;
}

export default ClientTabs;
