import React, {useState} from "react"
import MyPagination from "../../MyPagination"
import MySortedTh, {SortType} from "../../MySortTh"
import { VapUser } from "../../../Model/Model"


const VAPUsersTable = (props: any) => {
  const [theUsers, setTheUsers] = useState<VapUser[]>()
  const [searchKey, setSearchKey] = useState<string>()
  const [sortColumnName, setSortColumnName] = useState<string>("")
  const [sortType, setSortType] = useState<string>("")
  const [totalCount, setTotalCount] = useState<number>()

  if(theUsers !== props.data)
  {
    setTheUsers(props.data as VapUser[])
  }
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const changeCurrentPage = (p: number) =>{
    setCurrentPage(p)
  }

  const onPageSizeChange = (p: number) => {
    setPageSize(p)
    setCurrentPage(1)
  }

  const onSearchChange = (e: any) => {
    setSearchKey(e.target.value)
    setCurrentPage(1)
  }

  const getDisplayItems = () => {
    let theResult = Object.values(Object.assign({}, theUsers || []))
    if(searchKey)
    {
      theResult = theResult?.filter(x => x.email?.toLowerCase().includes(searchKey.toLowerCase()))
    }

    if(sortColumnName)
    {
      if(sortType === SortType.DESC)
      {
        theResult = (theResult as any[]).sort((a, b) => (b[sortColumnName] || "").toString().localeCompare(a[sortColumnName] || ""))
      }
      else{
        theResult = (theResult as any[]).sort((a, b) => (a[sortColumnName] || "").toString().localeCompare(b[sortColumnName] || ""))
      }
    }

    if(totalCount !== theResult.length)
    {
      setTotalCount(theResult.length)
    }
    
    return theResult
  }

  const onThClick = (theSortColumnName: string) => {
    if(!sortColumnName)
    {
      setSortColumnName(theSortColumnName)
      setSortType(SortType.ASC)
    }

    if(theSortColumnName === sortColumnName)
    {
      if(sortType === SortType.DESC)
      {
        setSortType(SortType.ASC)
      }
      else
      {
        setSortType(SortType.DESC)
      }
    }
    else{
      setSortColumnName(theSortColumnName)
      setSortType(SortType.ASC)
    }
  }

  return (
    <>
      <div>
      <div className="button-container float-left">
        <input className="searchButton" onChange={(e) => onSearchChange(e)} placeholder="Search"></input>
        </div>
        <div className="button-container float-right">
          <button
            className="btn btn-secondary"
            onClick={() => props.handleAddApprovedUsersShow()}
          >
            <i className="fas fa-plus"></i>&nbsp; Add New User
          </button>
          <button
            className="ml-2 btn btn-secondary"
            onClick={() => props.handleAddMultipleUsersShow()}
          >
            <i className="fas fa-plus"></i>&nbsp; Add Multiple Users
          </button>

          {/* <a className="ml-2 btn btn-secondary" href="/api/v1/User/Export/Approved">Export</a> */}
          <button
            className="ml-2 btn btn-secondary"
            onClick={() => props.handleExport()}
          >
            <i className="fas fa-print"></i>&nbsp; Export
          </button>

        </div>
      </div>

      <div>
          <table className="dataListTbMainList">
            <thead>
              <tr>
                <MySortedTh
                  CurrentSortName={sortColumnName}
                  SortAble={true}
                  SortColumnName={'name'}
                  SortType={sortType}
                  //Style={{ width: '5%', cursor: 'pointer' }}
                  Title="Name"
                  onThClick={() => onThClick('name')}
														/>
                <MySortedTh
                  CurrentSortName={sortColumnName}
                  SortAble={true}
                  SortColumnName={'email'}
                  SortType={sortType}
                  //Style={{ width: '5%', cursor: 'pointer' }}
                  Title="Email"
                  onThClick={() => onThClick('email')}
                />
                <MySortedTh
                  CurrentSortName={sortColumnName}
                  SortAble={true}
                  SortColumnName={'entities'}
                  SortType={sortType}
                  //Style={{ width: '5%', cursor: 'pointer' }}
                  Title="VAP Entities"
                  onThClick={() => onThClick('entities')}
                />
                <MySortedTh
                  CurrentSortName={sortColumnName}
                  SortAble={true}
                  SortColumnName={'localEntities'}
                  SortType={sortType}
                  //Style={{ width: '5%', cursor: 'pointer' }}
                  Title="Evolve Hub Entities"
                  onThClick={() => onThClick('localEntities')}
                />
                <MySortedTh
                  CurrentSortName={sortColumnName}
                  SortAble={true}
                  SortColumnName={'status'}
                  SortType={sortType}
                  //Style={{ width: '5%', cursor: 'pointer' }}
                  Title="Status"
                  onThClick={() => onThClick('status')}
                />
                <th style={{width: "100px"}}>Action</th>
              </tr>
            </thead>
            <tbody>
              {getDisplayItems() && (getDisplayItems() as VapUser[]).slice((currentPage - 1) * pageSize, currentPage * pageSize) 
              && (getDisplayItems() as VapUser[]).slice((currentPage - 1) * pageSize, currentPage * pageSize).map((item: VapUser) =>{
                return <tr key={item.id}>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>{item.entities && item.entities.map(entity => entity.entityDisplayName).join(',')}</td>
                <td>{item.localEntities && item.localEntities.map(entity => entity.entityDisplayName).join(',')}</td>
                <td>{item.status}</td>
                <td>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    if(item.status == 'New'){
                      props.handleAddApprovedUsersShow(item);
                    }
                    else{
                      //Edit
                      props.handleUpdateApprovedUsersShow(item);
                    }
                  }}
                >
                  <i className={item.status == 'New' ? "fas fa-plus" : "fas fa-edit"}></i>
                </button>
                </td>
              </tr>
              }) }
            </tbody>
          </table>
      {totalCount && <MyPagination totalCount={totalCount} onPageSizeChange={(p) => onPageSizeChange(p)} currentPage={currentPage}
      onClick={(p) => changeCurrentPage(p)}/>}
        </div>
    </>
  );
}

export default VAPUsersTable;
