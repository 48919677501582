import React, { useState } from "react";
import Select from "react-select";
import { Row, Col, Modal, Form, Button } from "react-bootstrap";

function ApprovedUserModal(props) {
  return (
    <Modal size="lg" show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="pending-users-form" onSubmit={props.handleSubmit}>
          <Row>
            <Form.Group as={Col} controlId="displayName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                defaultValue={props.user.displayName}
                disabled
              ></Form.Control>
            </Form.Group>

            <Form.Group as={Col} controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                defaultValue={props.user.title}
              ></Form.Control>
            </Form.Group>

            <Form.Group as={Col} controlId="company">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                defaultValue={props.user.company}
              ></Form.Control>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                disabled
                type="email"
                defaultValue={props.user.email}
              ></Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="userType">
              <Form.Label>User Type</Form.Label>
              <Form.Control as="select" defaultValue={props.user.userType}>
                <option>DNV Staff</option>
                <option>Distributor</option>
                <option>Utility Staff</option>
                <option>Contractor</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} controlId="role">
              <Form.Label>Account Type</Form.Label>
              <Form.Control as="select" defaultValue={props.user.role}>
                <option>Admin</option>
                <option>User</option>
              </Form.Control>
            </Form.Group>
          </Row>
          <Row>
            <Col>
              <h5>User Group Access</h5>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              {props.availableOptions &&
                <Select
                  className="dropdown"
                  placeholder="Select Option"
                  options={props.availableOptions}
                  value={props.availableOptions.length > 0 ? props.availableOptions.filter((obj) =>
                    props.selectedOptions.includes(obj.value)
                  ) : []} // set selected values
                  onChange={props.handleChange}
                  isMulti
                  isClearable
                />
              }
            </Col>
          </Row>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Cancel
        </Button>
        <Button variant="primary" type="submit" form="pending-users-form">
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ApprovedUserModal;
