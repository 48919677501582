import React, {useState} from "react";
import { Container, Row, Col, Modal, Form, Button } from "react-bootstrap";

function AddClientModal(props) {
    
    return (
        <Modal size="lg" show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Client</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="new-client-form" onSubmit={props.handleSubmit}>
        <Row>
            <Form.Group as={Col} controlId="name">
              <Form.Label>Client Name</Form.Label>
              <Form.Control as="input" defaultValue="" />
            </Form.Group>
            {props.selectedImage === "" ? null : (
              <img
                src={props.selectedImage}
                alt="image selected"
                className="img-thumbnail"
              />
            )}
            <Form.Group as={Col} controlId="image">
              <Form.File
                label="Upload Image"
                onChange={(event) => props.handleLogoChange(event)}
                required
              />
            </Form.Group>
          </Row>
          
          
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Cancel
        </Button>
        <Button variant="primary" type="submit" form="new-client-form">
          Add Client
        </Button>
      </Modal.Footer>
    </Modal>
    )
}

export default AddClientModal
