import React, { useState, useEffect } from "react";
import { Container, Row, Col, Tab, Nav, Button } from "react-bootstrap";
import "./Clients.css";
import axios from "axios";
import AdminClientPane from "../../../Components/AdminClientPane";
import AddClientModal from "../../../Components/Modals/AddClientModal";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { ToastContainer, toast } from "react-toastify";

function Clients() {
  const [clients, setClients] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [image, setImage] = useState();
  const [selectedImage, setSelectedImage] = useState("");
  const [updateClient, setUpdateClient] = useState(false);
  const [selectedTab, setSelectedTab] = useState();
  const [vapOptions, setvapOptions] = useState([]);

  const handleModalClose = () => {
    setModalShow(false);
    setImage(null);
    setSelectedImage("")
  };

  useEffect(() => {}, [updateClient]);
  useEffect(() => {
    axios
      .get("/api/v1/Client/All", { withCredentials: true })
      .then(function (response) {
        setClients(response.data.clients);
        if (response.data.clients.length > 0) {
          setSelectedTab("admin-" + response.data.clients[0].name);
        }
      })      
      .catch(function (error) {
        // handle error
        console.log(error.response);
      });
  }, []);
  useEffect(()=>{
    let url = "/api/v1/Products/VAPEntity";
    axios.get(url, { withCredentials: true })
    .then(function (response) {
      let vapEntityOptions = response.data.map((entity)=>{return  {value: entity.entityId, text: entity.entityDisplayName, url: entity.entityUrl }});
      setvapOptions(vapEntityOptions);
    }).catch(function (error) {
      // handle error
      console.log(error.response);
    })
  },[])
  //===============================================================================================
  //
  //===============================================================================================
  const handleLogoChange = (event) => {
    setSelectedImage(URL.createObjectURL(event.target.files[0]));
    setImage(event.target.files[0]);
  };

  function uploadImage() {
    let formData = new FormData();
    formData.append("file", image);
    let request = axios({
      method: "post",
      url: "/api/v1/Files/UploadImage",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
    return request;
  }

  const handleAddNewClientSubmit = (event) => {
    var name = event.target.name.value;

    event.preventDefault();
    if (image !== null) {
      uploadImage().then((response) => {
        var formData = new FormData();
        formData.append("name", name);
        formData.append("logoUrl", response.data.url);
        axios({
          method: "post",
          url: "/api/v1/Client/New",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            accept: "text/plain",
          },
          withCredentials: true,
        }).then((response) => {
          var newClients = clients;
          newClients.push({
            id: response.data.id,
            name: response.data.name,
            logoUrl: response.data.logoUrl,
          });
          setClients(newClients);
          setImage(null);
          setSelectedImage("");
          setModalShow(false);
        });
      });
    }
  };
  function onSubmitClientNameChange(name, logoUrl, client) {
    var newClients = clients;
    var index = newClients.findIndex((element) => element.id == client.id);
    newClients[index] = {
      id: client.id,
      name: name,
      logoUrl: logoUrl,
    };
    setClients(newClients);
    setSelectedTab("admin-" + name);
    setUpdateClient(!updateClient);
  }
  //================================================================================================
  //
  //=================================================================================================
  return (
    <>
    <ToastContainer />
    <div className="container-fluid max-width">
      <Col className="pb-5">
          <div className="my-4">
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="large" />}
                aria-label="breadcrumb"
            >
                <Link to="/admin">Admin</Link>
                <Typography className="MuiBreadcrumbs-li-last">
                    Manage Clients
                </Typography>
            </Breadcrumbs>
          </div>
          <Row className="admin-clients-tab-container">
            <Tab.Container
              activeKey={selectedTab}
              onSelect={(k) => {setSelectedTab(k);console.log(k)}}
            >
              <Row>
                <Col className="col-admin-sidebar">
                  <Nav variant="sidebar" className="flex-column admin-sidebar">
                    {clients && clients.length > 0 ? (
                      <>
                        {clients.map((client, key) => (
                          <Nav.Item key={client.id}>
                            <Nav.Link
                              key={client.id}
                              eventKey={"admin-" + client.name}
                            >
                              {client.name}
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </>
                    ):null}
                  </Nav>
                  <Button
                    className="btn btn-primary text-left"
                    onClick={() => setModalShow(true)}
                  >
                    <i className="fas fa-plus"></i>&nbsp;&nbsp;Add new client
                  </Button>
                </Col>
                <Col className="admin-clients-col">
                  <Tab.Content>
                    {clients.length > 0 ? (
                      <>
                        {clients.map((client) => (
                          <Tab.Pane key={client.id} eventKey={"admin-" + client.name}>
                            <AdminClientPane
                              client={client}
                              vapOptions={vapOptions?.filter(o => o.text.startsWith(client.name))}
                              onSubmitClientNameChange={onSubmitClientNameChange}
                            />
                          </Tab.Pane>
                        ))}
                      </>
                    ):null}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>

          </Row>
      </Col>
      <AddClientModal
        show={modalShow}
        handleLogoChange={handleLogoChange}
        handleClose={handleModalClose}
        handleSubmit={handleAddNewClientSubmit}
        image={image}
        selectedImage={selectedImage}
      />
    </div>
    </>
  )
}

export default Clients;
