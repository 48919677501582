import React, {useState, useEffect, useCallback, useRef} from "react";
import { Container, Row, Col, Form, Button, FormControl, Alert } from "react-bootstrap";
import axios from 'axios';
import Loader from 'react-loader-spinner';
import Select, { NonceProvider } from 'react-select';
import makeAnimated from 'react-select/animated';
import {FileDrop} from 'react-file-drop';
import "./PostForm.css"
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

function PostForm(props) {
  const [post, setPost] = useState({
    id: props.post ? props.post.id: null,
    category: props.post ? props.post.category : null,
    title: props.post ? props.post.title : null,
    summary: props.post ? props.post.summary : null,
    previewChars: props.post ? props.post.previewChars : null,
    imageUrl: props.post ? props.post.imageUrl : null,
    videoUrl: props.post ? props.post.videoUrl : null,
    isBanner: props.post ? props.post.isBanner : false,
    active: props.post? props.post.active: true
  });
  
  const [formType, setFormType] = useState(props.formType);
  const [allCategories, setAllCategories] = useState([]);
  const [selectedCats, setSelectedCats] = useState(props.post ? props.post.category:[]);
  const [postImage, setPostImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alertShow, setAlertShow] = useState(false);
  const [postVideo, setPostVideo] = useState(null);

  const supportedImageTypes = ["image/jpeg", "image/png"];
  const fileInputRef = useRef(null);
  const [imageValError, setImageValError] = useState("none");
  const videoInputRef = useRef(null);
  const supportedVideoTypes = ["video/ogg", "video/webm", "video/mp4"];
  const [videoValError, setVideoValError] = useState("none");
  const [validationError, setValidationError] = useState({
    category: "none",
    title: "none", 
    summary: "none",
    previewChars: "none",
    imageUrl: "none",
    videoUrl: "none"
  });

  const [summary, setSummary] = useState(props.post ? props.post.summary : '');
  
  useEffect(() => {
    axios
    .get("api/v1/ProductCategories/All", { withCredentials: true })
    .then(function (response) {
      if (response.data.categories.length > 0) {
       response.data.categories.forEach(category => {
         setAllCategories(catArray => [...catArray, {"value": category.name, "label": category.name}]);
       });
      };
    })
    .catch (function (error) {
     console.log(error.response);
   });
  }, []);

  const handlePostImageChange = (file) => {
    if (supportedImageTypes.includes(file.type)) {
      setPostImage(file);
      setImageValError("none");
    }
    else {
      setImageValError("inline-block");
    }
  };

  const onTargetClick = () => {
    fileInputRef.current.click()
  }

  const onDrop = useCallback(files => {handlePostImageChange(files[0])})

  function uploadImage() {
    let formData = new FormData();
    formData.append("file", postImage);
    let request = axios({
      method: "post",
      url: "/api/v1/Files/UploadImage",
      data: formData,
      headers: {"Content-Type": "multipart/form-data"},
    });
    return request;
  }

  const handlePostVideoChange = (file) => {
    if (supportedVideoTypes.includes(file.type)) {
      setPostVideo(file);
      setVideoValError("none");
    }
    else {
      setVideoValError("inline-block");
    }
  };

  const onVideoTargetClick = () => {
    videoInputRef.current.click()
  }

  const onVideoDrop = useCallback(files => {handlePostVideoChange(files[0])})

  function uploadVideo() {
    let formData = new FormData();
    formData.append("file", postVideo);
    let request = axios({
      method: "post",
      url: "/api/v1/Files/UploadVideo",
      data: formData,
      headers: {"Content-Type": "multipart/form-data"},
    });
    return request;
  }

  function validateForm(event) {
    let target = event.target;
    let validation = true;

    if(!post.isBanner) {
      if (selectedCats.length==0){
      setValidationError(prevState => ({...prevState, category: "inline-block"}));
      validation = false;
    } else {
      setValidationError(prevState => ({...prevState, category: "none"}));
      };
    };
    if(target.title.value.length==0) {
      setValidationError(prevState => ({...prevState, title: "inline-block"}));
      validation = false;
    } else {
      setValidationError(prevState => ({...prevState, title: "none"}));
    };
     if(summary.length==0) {
       setValidationError(prevState => ({...prevState, summary: "inline-block"}));
       validation = false;
     } else {
       setValidationError(prevState => ({...prevState, summary: "none"}));
     };
    if(target.previewChars.value.length==0) {
      setValidationError(prevState => ({...prevState, previewChars: "inline-block"}));
      validation = false;
    } else {
      setValidationError(prevState => ({...prevState, previewChars: "none"}));
    };
    if(!postImage && !post.imageUrl) {
      setValidationError(prevState => ({...prevState, imageUrl: "inline-block"}));
      validation = false;
    } else {
      setValidationError(prevState => ({...prevState, imageUrl: "none"}));
    };

    return validation;
  }

  function handleFormSubmit(event) {
    event.preventDefault();

    var isBanner = post.isBanner;
    var active = post.active;
    var category = post.isBanner? null : selectedCats;
    var title = event.target.title.value;
    //var summary = event.target.summary.value;
    var previewChars = parseInt(event.target.previewChars.value);
    var validation = validateForm(event);
    var formData = new FormData();
    formData.append("isBanner", isBanner);
    if (!isBanner) {
      formData.append("category", category);
    };
    formData.append("title", title);
    formData.append("summary", summary);
    formData.append("previewChars", previewChars);
    formData.append("active", active);
    
    if (validation) {
        setIsLoading(true);
        if (formType == "new") {
          uploadImage().then((response) => {
            var imgUrl = response.data.url;
            formData.append("imageUrl", imgUrl);
            if(postVideo){
              uploadVideo().then((videoRes) => {
                var videoUrl = videoRes.data.url;
                formData.append("videoUrl", videoUrl);
              })
              handlePostSubmit(formData);
            }
            else{
              handlePostSubmit(formData);
            }
          });
        } 
        else if (formType=="update") {
          formData.append("id", post.id)
          if (postImage) {
            uploadImage().then((response) => {
              var imgUrl = response.data.url;
              formData.append("imageUrl", imgUrl);
              if(postVideo){
                uploadVideo().then((videoRes) => {
                  var videoUrl = videoRes.data.url;
                  formData.append("videoUrl", videoUrl);
                  handlePostSubmit(formData);
                  props.handleModalClose();
                })
              }
              else{
                handlePostSubmit(formData);
                props.handleModalClose()
              }              
            });
          }
          else if(postVideo){
            uploadVideo().then((videoRes) => {
              var videoUrl = videoRes.data.url;
              formData.append("videoUrl", videoUrl);
              var imgUrl = post.imageUrl;
              formData.append("imageUrl", imgUrl);
              handlePostSubmit(formData);
              props.handleModalClose();
            })
          } 
          else {
            var imgUrl = post.imageUrl;
              formData.append("imageUrl", imgUrl);
              handlePostSubmit(formData);
              props.handleModalClose()
          }
        };
    };
  }
  
  function handlePostSubmit(formData) {
    axios({
      method:"post",
      url: "api/v1/Post/".concat(formType),
      data:formData,
      headers: {
        "Content-Type": "multipart/form-data",
        accept:"text/plain"
      },
      withCredentials: true
    }).then(response => {
      if (props.handleUpdatedResult) {
        props.handleUpdatedResult(response.data);
      }
      setAlertShow(true);
      setIsLoading(false);
  });
  }

  function onSelectOptionsChange(e) {
    if (e) 
      setSelectedCats(e.map(item => item.value));
     else
      setSelectedCats([]);
  }; 

  function onPostTypeChange(e) {
    setPost({...post, isBanner: e.target.checked});
  }

  function onActiveStatusChange(e) {
    setPost({...post, active: e.target.checked});
  }

  const modules = {
    toolbar: [
      [{ font: [] }, { size: [] }],
      [{ align: [] }, 'direction' ],
      [ 'bold', 'italic', 'underline', 'strike' ],
      [{ color: [] }, { background: [] }],
      [{ script: 'super' }, { script: 'sub' }],
      ['blockquote', 'code-block' ],
      [{ list: 'ordered' }, { list: 'bullet'}, { indent: '-1' }, { indent: '+1' }],
      [ 'link', 'image', 'video' ],
      [ 'clean' ]
    ],
  };

  return (
    <>
    {isLoading? 
      <Loader className="nothing-display"
      type="Oval"
      color="#0F204B"
      height={100}
      width={100}
    />: 
    <Form
      id="new-post-form"
      onSubmit={(event) => handleFormSubmit(event)}
      >
      {alertShow?
      <Alert variant="success" onClose={() => setAlertShow(false)} dismissible>The request was submitted successfully.</Alert>
       :null}
       <Form.Group as={Col}>
       <Row className="my-4 col" xs={1} md={2}>
         {formType=="update"? null : 
         <>
         <Form.Check type="checkbox" className="col-lg-4" label="Banner Post" checked={post.isBanner} onChange={onPostTypeChange}/>
         <Form.Check type="checkbox" className="col-lg-4" label="Is Active" checked={post.active} onChange={onActiveStatusChange}/>
         </>}  
        </Row>
       </Form.Group>
       {post.isBanner? null :         
        <Form.Group as={Col} controlId="category">
        <Form.Label>Category</Form.Label>
        <Select   options={allCategories} 
                  key={post.category?allCategories.filter((obj) =>
                    selectedCats.includes(obj.value)):[]}
                  defaultValue={post.category?allCategories.filter((obj) =>
                    selectedCats.includes(obj.value)):[]}
                  onChange={onSelectOptionsChange} 
                  closeMenuOnSelect={false} 
                  components={makeAnimated} 
                  isMulti
                  isSearchable
                  isClearable/>
        <small style={{color: "red", display:`${validationError.category}`}}>Categories must be selected.</small>
        </Form.Group>
        }
      <Form.Group as={Col} controlId="title">
        <Form.Label>Title</Form.Label>
        <Form.Text className="helpInline" id="titleHelpInline" muted>&nbsp;(Max Charcters: 60)</Form.Text>
        <Form.Control type="text" as="input" maxLength="60" defaultValue={post.title?post.title:null}/>
        <small style={{color: "red", display:`${validationError.title}`}}>This field cannot be empty.</small>
        </Form.Group>
      <Form.Group as={Col} controlId="summary">
        <Form.Label>Summary</Form.Label>
        {/* <Form.Text className="helpInline"  id="summaryHelpInline" muted>&nbsp;(Max Charcters: 500)</Form.Text> */}
        <ReactQuill theme="snow" modules={modules} value={summary} onChange={setSummary} />
        {/* <Form.Control type="text" as="textarea" rows="5" maxLength="500" defaultValue={post.summary?post.summary:null}/> */}
        <small style={{color: "red", display:`${validationError.summary}`}}>This field cannot be empty.</small>
      </Form.Group>
      <Form.Group as={Col} controlId="previewChars">
        <Form.Label>Maximum Characters to Preview</Form.Label>
        <Form.Text className="helpInline" id="previewCharsHelpInline" muted>&nbsp;(number only)</Form.Text>
        <Form.Control type="text" as="input" pattern="[0-9]*" defaultValue={post.previewChars?post.previewChars:"50"}/>
        <small style={{color: "red", display:`${validationError.previewChars}`}}>This field cannot be empty.</small>
      </Form.Group>
      {postImage? <Col><img id="postImage" src={URL.createObjectURL(postImage)}/></Col>:
      post.imageUrl? <Col><img id="postImage" src={post.imageUrl}/></Col>:null}
      <Form.Group as={Col} controlId="image" className="mb-0">
        <small style={{color: "red", display:`${imageValError}`}}>Only single image file in .jpg or .png format is supported!</small><br/>
        <Form.Label>Image</Form.Label>
        <FileDrop onDrop={onDrop} onTargetClick={onTargetClick}>Drag and drop file here or click to browse.</FileDrop>
        <Form.File id="imageUpload" ref={fileInputRef} onChange={(event) => handlePostImageChange(event.target.files[0])} custom/>
        <small style={{color: "red", display:`${validationError.imageUrl}`}}>An image must be uploaded.</small>
      </Form.Group>
      {postVideo? <Col><video id="postVideo" controls muted autoplay="true" ><source  src={URL.createObjectURL(postVideo)}/></video></Col>:
      post.videoUrl? <Col><video id="postVideo" controls muted autoPlay="true" ><source src={post.videoUrl}/></video></Col>:null}
      <Form.Group as={Col} controlId="video" className="mb-0">
        <small style={{color: "red", display:`${videoValError}`}}>Only single image file in .jpg or .png format is supported!</small><br/>
        <Form.Label>Video</Form.Label>
        <FileDrop onDrop={onVideoDrop} onTargetClick={onVideoTargetClick}>Drag and drop file here or click to browse.</FileDrop>
        <Form.File id="videoUpload" ref={videoInputRef} onChange={(event) => handlePostVideoChange(event.target.files[0])} custom/>
        <small style={{color: "red", display:`${validationError.imageUrl}`}}>An image must be uploaded.</small>
      </Form.Group>
      <Col>
        <Button className="float-right" variant="primary" type="submit">Save</Button>
      </Col> 
      </Form>
    }</>  
    );
} 

export default PostForm;