import React, { useState, useEffect } from 'react';
import axios from 'axios';

export const MyProductsContext = React.createContext();

function MyProductsProvider(props) {
  const [myProducts, setMyProducts] = useState([]);

  useEffect(() => {
    axios.get('/api/v1/Products/My', { withCredentials: true })
        .then(function (response) {

        setMyProducts(response.data);

      })
      .catch(function (error) {
        // handle error
        console.log(error.response);
      })
  }, []);

  return (
    <MyProductsContext.Provider value={{ myProducts }}>
      {props.children}
    </MyProductsContext.Provider>
  )
}

const MyProductsConsumer = MyProductsContext.Consumer;

export { MyProductsProvider, MyProductsConsumer };