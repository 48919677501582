
import React, { useState, useEffect } from "react";
import "./ServiceModal.css";
import { Container, Row, Col, Modal, Form, Button } from "react-bootstrap";

function DocumentModal(props) {
    //=====================================================================================================
    // Functions
    //=====================================================================================================
  
    return (
        <Modal size="lg" show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="service-form" onSubmit={props.handleSubmit}>
                    <Row>
                        <Form.Group as={Col} controlId="title">
                            <Form.Label>Document title</Form.Label>
                            <Form.Control
                                as="input" 
                                defaultValue={props.document.title}
                                required
                            ></Form.Control>
                        </Form.Group>
                        {!props.isAdd && <Form.Group as={Col} controlId="owner">
                            <Form.Label>Document Owner</Form.Label>
                            <Form.Control
                                as="input" disabled
                                defaultValue={props.document.owner}
                              
                            ></Form.Control>
                        </Form.Group> }
                       
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                defaultValue={props.document.description}
                            ></Form.Control>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="category">
                            <Form.Label>Category</Form.Label>                          
                            <Form.Control as="select" defaultValue={props.selectedDocCategory}>
                                {props.categories.length > 0 ?

                                    props.categories.map((category) => (
                                        <option key={category.id}>{category.name}</option>
                                    ))
                                    : <option disabled>No Options here</option>
                                }
                            </Form.Control>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="location">
                            <Form.Label>Location of Document link</Form.Label>
                            <Form.Control
                                as="input"
                                defaultValue={props.document.documentLocation}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} controlId="locationdatechanged">
                            <Form.Label></Form.Label>
                            <Form.Control
                                as="input" disabled
                                defaultValue={props.document.locationupdatedDate}
                                required
                            ></Form.Control>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="file">
                            <Form.File
                                label="New Upload file"
                                onChange={(event) => props.handleFileChange(event)}
                                accept=".pdf,.doc,.xlsx,.xls,.csv"
                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="filename">
                            <Form.Label>Existing File</Form.Label>
                            <Form.Control
                                as="input" disabled
                                defaultValue={props.document.fileName}
                            ></Form.Control>
                           
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" type="submit" form="service-form">
                    {props.buttonText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DocumentModal;
