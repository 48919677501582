import React from "react";
import { Container, Row, Col, Modal, Form, Button } from "react-bootstrap";
import Select from "react-select";

function AddMultipleUsersModal(props) {
  return (
    <Modal size="lg" show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Multiple Users</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="multiple-users-form" onSubmit={props.handleSubmit}>
          <Row>
            <Form.Group as={Col} controlId="file">
              <Form.File
                label="Upload Excel"
                onChange={(event) => props.handleFileChange(event)}
                accept=".xls,.xlsx"
                required
              />
            </Form.Group>
          </Row>
          <a href={props.blobUrl}>Download template</a>
          <Row>
            <Col>
              <h5>User Group Access</h5>
              <hr />
            </Col>
          </Row>
          {/* <Row>
            
              <Form.Group as={Col} controlId="client">
                <Form.Label>Client</Form.Label>
                <Form.Control
                  as="select"
                  defaultValue={props.clients.length > 0 ? props.clients[0].name: null}
                  onChange={props.handleClientChange}
                >
                  {props.clients.length > 0 ? (
                    <>
                  {props.clients.map((client) => (
                    <option key={client.id}>{client.name}</option>
                  ))}
                  </>
                  ) : null}
                </Form.Control>
              </Form.Group>
            
          </Row> */}
          <Row>
            <Col className="col-12">
            
              {props.availableOptions &&
                <Select
                  className="dropdown"
                  placeholder="Select Option"
                  options={props.availableOptions}
                  value={props.availableOptions.length > 0 ? props.availableOptions.filter((obj) =>
                    props.selectedOptions.includes(obj.value)
                  ): []} // set selected values
                  onChange={props.handleChange}
                  isMulti
                  isClearable
                />
              }
            </Col>
            {!props.isValid &&
              <Col className="col-12">
                <div className="alert alert-danger" role="alert">
                  User should have at least one subscribed service
                </div>
              </Col>
            }

          </Row>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Cancel
        </Button>
        <Button variant="primary" type="submit" form="multiple-users-form">
          Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddMultipleUsersModal;
