import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Tab, Nav, Button } from "react-bootstrap";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Link } from "react-router-dom";
import axios from 'axios';
import Loader from 'react-loader-spinner';
import './ManagePost.css';
import ManagePostModal from '../../../Components/Modals/ManagePostModal';
import ManagePostsTable from "../../../Components/Tables/ManagePostsTable";

function ManagePost() {
  const emptyPost = {
    id: null,
    catgory: [],
    title: "",
    summary: "",
    previewChars: "",
    imageUrl: "",
    videoUrl: ""
  }
  const [livePosts, setLivePosts] = useState([]);
  const [archivedPosts, setArchivedPosts] = useState([]);
  const [liveBanner, setLiveBanner] = useState([]);
  const [archivedBanner, setArchivedBanner] = useState([]);
  const [selectedPost, setSelectedPost] = useState(emptyPost);
  const [modalShow, setModalShow] = useState(false);
  const [formType, setFormType] = useState("update");
  const handleModalClose = () => {setModalShow(false)};
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    var livePostList = [];
    var archivedPostList = [];
    var liveBannerList = [];
    var archivedBannerList = [];
    axios
       .get("/api/v1/Post/All")
       .then((response) => {
          response.data.posts.forEach(post => {
            if (post.active==true & post.isBanner==false) {
              livePostList.push(post);
            }
            else if (post.active==false & post.isBanner==false) {
              archivedPostList.push(post);
            }
            else if (post.active==true & post.isBanner==true) {
              liveBannerList.push(post);
            }
            else if (post.active==false & post.isBanner==true) {
              archivedBannerList.push(post);
            };
          });
          setLivePosts(livePostList);
          setArchivedPosts(archivedPostList);
          setLiveBanner(liveBannerList);
          setArchivedBanner(archivedBannerList);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }, []);

  function TabPane(props) {
    let posts = props.posts;
    let eventKey = props.eventKey;
    var flag = props.flag;
    var isBanner = props.isBanner;

    return (
      <Tab.Pane eventKey={eventKey}>
      {isLoading? (
        <Loader
        className="nothing-display"
        type="Oval"
        color="#0F204B"
        height={100}
        width={100}
        />
      ): (
        <>
      <ManagePostsTable 
      data={posts}
      handleUpdatePost={handleUpdatePost}
      handleArchivePost = {handleArchivePost}
      flag = {flag}
      isBanner = {isBanner}
      />
      </>
      )}
    </Tab.Pane>
    )
  }

  function handleArchivePost(post, flag, isBanner) {
    setSelectedPost(post);
    var formData = new FormData();
    formData.append("id", post.id);
    formData.append("active", flag);
    setIsLoading(true);
    axios({
      method: "post",
      url: "/api/v1/post/update",
      data: formData,
      // withCredentials: true,
      headers: {"Content-Type": "multipart/form-data"}
    }).then((response) => {
      if (flag) {
        if (isBanner) {
          let items = archivedBanner;
          const index = archivedBanner.findIndex(
            (element) => element.id == post.id
          );
          items.splice(index, 1);
          if (liveBanner.length!=0) {
            setArchivedBanner(items.concat(liveBanner));
          }
          setLiveBanner([post]);
        }
        else {
          let items = archivedPosts;
          const index = archivedPosts.findIndex(
            (element) => element.id == post.id
          );
          items.splice(index, 1);
          setArchivedPosts(items);
          setLivePosts([...livePosts, post]);
        }
        setIsLoading(false);
      }
      else {
        if (isBanner) {
          setLiveBanner([]);
          setArchivedBanner([...archivedBanner, post]);
        }
        else {
          let items = livePosts;
          const index = livePosts.findIndex(
            (element) => element.id == post.id
          );
          items.splice(index, 1);
          setLivePosts(items);
          setArchivedPosts([...archivedPosts, post]);
        }
        setIsLoading(false);
      };
    });
  }

  function handleUpdatePost (post) {
    setSelectedPost(post);
    setModalShow(true);
  }

  function handleUpdatedResult(post) {
    let indexLive = livePosts.findIndex(
      (element) => element.id == post.id
    );
    let indexArchive =archivedPosts.findIndex(
      (element) => element.id == post.id
    );
    let indexLiveBanner = liveBanner.findIndex(
      (element) => element.id == post.id
    );
    let indexArchivedBanner =archivedBanner.findIndex(
      (element) => element.id == post.id
    );
    if (indexLive!=-1) {
      let items = [...livePosts];
      items[indexLive] = post;
      setLivePosts(items);
    };
    if (indexArchive!=-1) {
      let items = [...archivedPosts];
      items[indexArchive] = post;
      setArchivedPosts(items);
    };
    if (indexLiveBanner!=-1) {
      let items = [...liveBanner];
      items[indexLiveBanner] = post;
      setLiveBanner(items);
    };
    if (indexArchivedBanner!=-1) {
      let items = [...archivedBanner];
      items[indexArchivedBanner] = post;
      setArchivedBanner(items);
    };
  }

    return (
      <div className="container pb-5">
        <Row>
          <Col className="my-4">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="large" />}
              aria-label="breadcrumb"
            >
              <Link to="/admin">Admin</Link>
              <Typography className="MuiBreadcrumbs-li-last">
                Manage Posts
              </Typography>
            </Breadcrumbs>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="tabs">
              <Tab.Container defaultActiveKey="live">
                <Nav variant="tabs" as="ul">
                  <Nav.Item as="li">
                    <Nav.Link eventKey="live">Live Posts</Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link eventKey="archived">Archived Posts</Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link eventKey="liveBanner">Live Banner Post</Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link eventKey="archivedBanner">Archived Banner Posts</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <TabPane eventKey="live" posts={livePosts} flag={false} isBanner={false}/>
                  <TabPane eventKey="archived" posts={archivedPosts} flag={true} isBanner={false}/>      
                  <TabPane eventKey="liveBanner" posts={liveBanner} flag={false} isBanner={true}/>    
                  <TabPane eventKey="archivedBanner" posts={archivedBanner} flag={true} isBanner={true}/>
                 </Tab.Content>
              </Tab.Container>
              <ManagePostModal 
                    post={selectedPost} 
                    title={"Update Post"} 
                    formType={formType}
                    show={modalShow} 
                    handleUpdatedResult={handleUpdatedResult}
                    handleModalClose={handleModalClose}
                    />
            </div>
          </Col>
        </Row>
      </div>
      );
};

export default ManagePost;